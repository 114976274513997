<template>
  <div class="customer_support_dashboard">
    <div style="font-size: 22px; color: gray;padding:10px">Customer Support Dashboard</div>
    <div style="margin:10px">
      <v-row>
        <v-col cols="12">
          <v-card elevation="12">
          </v-card>
        </v-col>


        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              White Glove Open Orders
            </v-card-title>
            <v-card-text>
              <Vue3EasyDataTable
                :headers = "open_white_glove_headers"
                :items="sales_order_data.open"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
              >

                <template #item-sales_order_number="item">
                  <span class="dashboard-link" @click="openSalesOrderDetailPopup(item.sales_order_number)">{{item.sales_order_number}}</span>
                </template>

                <template #item-order_date_sortable="item">
                  {{item.order_date_formatted}}
                </template>

              <template #item-internal_notes="item">
                <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%;width:200px">
                  <div style="font-style:italic">{{n.note}}</div>
                  <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by_name}}</div>
                </div>
              </template>

              </Vue3EasyDataTable>
              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{sales_order_data.open.length}}</div>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              Recently Completed White Glove Orders (completed in the last 7 days)
            </v-card-title>
            <v-card-text>
              <Vue3EasyDataTable
                :headers = "recently_completed_white_glove_headers"
                :items="sales_order_data.recently_completed"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
              >

                <template #item-order_date_sortable="item">
                  {{item.order_date_formatted}}
                </template>

                <template #item-completed_date_sortable="item">
                  {{item.completed_date_formatted}}
                </template>

              <template #item-internal_notes="item">
                <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%;width:200px">
                  <div style="font-style:italic">{{n.note}}</div>
                  <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by_name}}</div>
                </div>
              </template>

              </Vue3EasyDataTable>
              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{sales_order_data.recently_completed.length}}</div>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              Open Shipments
            </v-card-title>
            <v-card-text>
              <Vue3EasyDataTable
                :headers = "open_shipments_headers"
                :items="sales_order_data.open_shipments"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
              >

              <template #item-sales_order_number="item">
                <span class="dashboard-link" @click="openSalesOrderDetailPopup(item.sales_order_number)">{{item.sales_order_number}}</span>
                <div v-if="item.white_glove_support_order" style="background: yellow;text-align: center;font-size:8px;font-weight: bold;width:65px;border:1px solid black;border-radius: 4px;">WHITE GLOVE</div>
              </template>

              <template #item-sales_order_eta_sortable="item">
                {{item.sales_order_eta_formatted}}
              </template>

              <template #item-sales_order_eta_last_updated_sortable="item">
                {{item.sales_order_eta_last_updated_formatted}}
              </template>

              <template #item-order_date_sortable="item">
                  {{item.order_date_formatted}}
              </template>

              <template #item-ship_date_sortable="item">
                  {{item.ship_date_formatted}}
              </template>              

              <template #item-internal_notes="item">
                <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%;width:200px">
                  <div style="font-style:italic">{{n.note}}</div>
                  <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by_name}}</div>
                </div>
              </template>

              </Vue3EasyDataTable>
              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{sales_order_data.open_shipments.length}}</div>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              Recently Closed Shipments (shipping status marked 'Complete' in the last 10 days)
            </v-card-title>
            <v-card-text>
              <Vue3EasyDataTable
                :headers = "recently_closed_shipments_headers"
                :items="sales_order_data.recently_closed_shipments"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
              >

              <template #item-sales_order_number="item">
                <span class="dashboard-link" @click="openSalesOrderDetailPopup(item.sales_order_number)">{{item.sales_order_number}}</span>
                <div v-if="item.white_glove_support_order" style="background: yellow;text-align: center;font-size:8px;font-weight: bold;width:65px;border:1px solid black;border-radius: 4px;">WHITE GLOVE</div>
              </template>

              <template #item-sales_order_eta_sortable="item">
                {{item.sales_order_eta_formatted}}
              </template>

              <template #item-sales_order_eta_last_updated_sortable="item">
                {{item.sales_order_eta_last_updated_formatted}}
              </template>

              <template #item-order_date_sortable="item">
                  {{item.order_date_formatted}}
              </template>


              <template #item-shipping_status_complete_timestamp_sortable="item">
                  {{item.shipping_status_complete_timestamp_formatted}}
              </template>

              <template #item-ship_date_sortable="item">
                  {{item.ship_date_formatted}}
              </template>              

              <template #item-internal_notes="item">
                <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%;width:200px">
                  <div style="font-style:italic">{{n.note}}</div>
                  <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by_name}}</div>
                </div>
              </template>

              </Vue3EasyDataTable>
              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{sales_order_data.recently_closed_shipments.length}}</div>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card-text>   
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              Open Drop Ship POs
            </v-card-title>
            <v-card-text>
              <Vue3EasyDataTable
                :headers = "open_drop_ships_headers"
                :items="open_drop_ships"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
              >

                <template #item-po_number="item">
                  <span class="dashboard-link" v-if="item.po_number" @click="openPurchaseOrderDetailPopup(item.po_number)">{{item.po_number}}</span>
                </template>

                <template #item-po_date_sortable="item">
                  {{item.po_date_formatted}}
                </template>

                <template #item-vendor_name="item">
                  <router-link :to="{ name: 'vendor', params: {vendorpageid: item.vendor_page ? item.vendor_page : 'null'}}" target="_blank" style="color:#ff7214;font-weight: bold">{{item.vendor_name}}</router-link>
                </template>

                <template #item-internal_notes="item">
                  <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%">
                    <div style="font-style:italic">{{n.note}}</div>
                    <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
                  </div>
                </template>

              </Vue3EasyDataTable>
              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{open_drop_ships.length}}</div>
                <v-spacer></v-spacer>

                <v-btn style="background:#ff7214;color:white;height:15px;width:50px;font-size:8px;" @click="exportOpenDropships()" :disabled="open_drop_ships.length === 0">EXPORT</v-btn>

              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              Recently Completed Drop Ship POs (completed in the last 7 days)
            </v-card-title>
            <v-card-text>
              <Vue3EasyDataTable
                :headers = "recently_completed_drop_ships_headers"
                :items="recently_completed_drop_ships"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
              >
                <template #item-po_date_sortable="item">
                  {{item.po_date_formatted}}
                </template>

                <template #item-po_completed_date_sortable="item">
                  {{item.po_completed_date_formatted}}
                </template>                

                <template #item-vendor_name="item">
                  <router-link :to="{ name: 'vendor', params: {vendorpageid: item.vendor_page ? item.vendor_page : 'null'}}" target="_blank" style="color:#ff7214;font-weight: bold">{{item.vendor_name}}</router-link>
                </template>

                <template #item-internal_notes="item">
                  <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%">
                    <div style="font-style:italic">{{n.note}}</div>
                    <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
                  </div>
                </template>

              </Vue3EasyDataTable>
              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{recently_completed_drop_ships.length}}</div>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              Backorder Past Due or Missing ETAs
            </v-card-title>  
            <v-card-text>
              <Vue3EasyDataTable
                :headers = "backorder_past_due_missing_headers"
                :items="backorders.past_due_missing_etas"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
              >

              <template #item-order_number="item">
                <span class="dashboard-link" @click="openSalesOrderDetailPopup(item.order_number)">{{item.order_number}}</span>
                <div v-if="item.white_glove_support_order" style="background: yellow;text-align: center;font-size:8px;font-weight: bold;width:65px;border:1px solid black;border-radius: 4px;">WHITE GLOVE</div>
              </template>

              <template #item-module_po_number="item">
                <div style='color:#ff7214;cursor:pointer;font-weight:bold' @click="openPurchaseOrderDetailPopup(item.module_po_number)" v-if="item.eta !== 'Linked PO not open in Nest' ">
                  {{item.module_po_number}}
                </div>
                <div v-if="item.eta == 'Linked PO not open in Nest'">{{item.module_po_number}}</div>
                <div v-if="item.unlinked_po_number" style="background: pink;text-align: center;font-size:8px; font-weight: bold;width:65px;border:1px solid black;border-radius: 4px;">UNLINKED PO</div>
              </template>

              <template #item-order_date_sortable="item">
                {{item.order_date_formatted}}
              </template>

              <template #item-eta_sortable="item">
                {{item.eta_formatted}}
              </template>

              <template #item-eta_last_provided_sortable="item">
                {{item.eta_last_provided_formatted}}
              </template>

              <template #item-internal_notes="item">
                <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%;min-width:100px">
                  <div style="font-style:italic">{{n.note}}</div>
                  <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by_name}} {{n.submitted_by_email}}</div>
                </div>
              </template>

              </Vue3EasyDataTable>
              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{backorders.past_due_missing_etas.length}}</div>
                <v-spacer></v-spacer>
                <v-btn style="background:#ff7214;color:white;height:15px;width:50px;font-size:8px;" @click="exportBackorerPastDueMissingEta()" :disabled="backorders.past_due_missing_etas === 0">EXPORT</v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              PO Closed but Sales Order Still Open
            </v-card-title>  
            <v-card-text>
              <Vue3EasyDataTable
                :headers = "backorder_po_not_open_in_nest_headers"
                :items="backorders.po_not_open_in_nest"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
              >

              <template #item-order_number="item">
                <span class="dashboard-link" @click="openSalesOrderDetailPopup(item.order_number)">{{item.order_number}}</span>
                <div v-if="item.white_glove_support_order" style="background: yellow;text-align: center;font-size:8px;font-weight: bold;width:65px;border:1px solid black;border-radius: 4px;">WHITE GLOVE</div>
              </template>

              <template #item-module_po_number="item">
                <div style='color:#ff7214;cursor:pointer;font-weight:bold' @click="openPurchaseOrderDetailPopup(item.module_po_number)" v-if="item.eta !== 'Linked PO not open in Nest' ">
                  {{item.module_po_number}}
                </div>
                <div v-if="item.eta == 'Linked PO not open in Nest'">{{item.module_po_number}}</div>
                <div v-if="item.unlinked_po_number" style="background: pink;text-align: center;font-size:8px; font-weight: bold;width:65px;border:1px solid black;border-radius: 4px;">UNLINKED PO</div>
              </template>

              <template #item-order_date_sortable="item">
                {{item.order_date_formatted}}
              </template>

              <template #item-eta_sortable="item">
                {{item.eta_formatted}}
              </template>

              <template #item-eta_last_provided_sortable="item">
                {{item.eta_last_provided_formatted}}
              </template>

              <template #item-internal_notes="item">
                <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%;min-width:100px">
                  <div style="font-style:italic">{{n.note}}</div>
                  <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by_name}} {{n.submitted_by_email}}</div>
                </div>
              </template>

              </Vue3EasyDataTable>
              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{backorders.po_not_open_in_nest.length}}</div>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              Backorder ETA > 14 Days
            </v-card-title>  
            <v-card-text>
              <Vue3EasyDataTable
                :headers = "backorder_eta_more_than_14_days_headers"
                :items="backorders.eta_more_than_14_days"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
              >

              <template #item-order_number="item">
                <span class="dashboard-link" @click="openSalesOrderDetailPopup(item.order_number)">{{item.order_number}}</span>
                <div v-if="item.white_glove_support_order" style="background: yellow;text-align: center;font-size:8px;font-weight: bold;width:65px;border:1px solid black;border-radius: 4px;">WHITE GLOVE</div>
              </template>

              <template #item-module_po_number="item">
                <div style='color:#ff7214;cursor:pointer;font-weight:bold' @click="openPurchaseOrderDetailPopup(item.module_po_number)">
                  {{item.module_po_number}}
                </div>
                <div v-if="item.unlinked_po_number" style="background: pink;text-align: center;font-size:8px; font-weight: bold;width:65px;border:1px solid black;border-radius: 4px;">UNLINKED PO</div>
              </template>

              <template #item-order_date_sortable="item">
                {{item.order_date_formatted}}
              </template>

              <template #item-eta_sortable="item">
                {{item.eta_formatted}}
              </template>

              <template #item-eta_last_provided_sortable="item">
                {{item.eta_last_provided_formatted}}
              </template>

              <template #item-internal_notes="item">
                <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%;min-width:100px">
                  <div style="font-style:italic">{{n.note}}</div>
                  <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by_name}} {{n.submitted_by_email}}</div>
                </div>
              </template>

              </Vue3EasyDataTable>
              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{backorders.eta_more_than_14_days.length}}</div>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              Backorder Products Missing PO
            </v-card-title>  
            <v-card-text>
              <Vue3EasyDataTable
                :headers = "backorder_missing_po_headers"
                :items="backorders.missing_po"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
              >

              <template #item-order_number="item">
                <span class="dashboard-link" @click="openSalesOrderDetailPopup(item.order_number)">{{item.order_number}}</span>
                <div v-if="item.white_glove_support_order" style="background: yellow;text-align: center;font-size:8px;font-weight: bold;width:65px;border:1px solid black;border-radius: 4px;">WHITE GLOVE</div>
              </template>

              <template #item-order_date_sortable="item">
                {{item.order_date_formatted}}
                <div v-if="item.older_than_five_days" style="background: pink;text-align: center;font-size:8px;font-weight: bold;width:65px;border:1px solid black;border-radius: 4px;">OLD ORDER</div>
              </template>

              <template #item-internal_notes="item">
                <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%;min-width:100px">
                  <div style="font-style:italic">{{n.note}}</div>
                  <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by_name}} {{n.submitted_by_email}}</div>
                </div>
              </template>

              </Vue3EasyDataTable>
              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{backorders.missing_po.length}}</div>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>
    </div>
  </div>
  <PurchaseOrderDetailPopUp v-if="po_detail" :po='po_for_component' @close="closePurchaseOrderDetailPopup()" />

  <SalesOrderDetailPopUp v-if="sales_order_detail" :sales_order='sales_order_for_component' @close="closeSalesOrderDetailPopup()" />
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment-business-days'
import _ from 'lodash'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import XLSX from 'xlsx'
import PurchaseOrderDetailPopUp from '@/components/PurchaseOrderDetailPopUp.vue'
import SalesOrderDetailPopUp from '@/components/SalesOrderDetailPopUp.vue'

export default {
  name: 'CustomerSuppoetDashboard',
  components: {Vue3EasyDataTable,PurchaseOrderDetailPopUp,SalesOrderDetailPopUp},
  mixins: [],
  data(){
    return {
      open_drop_ships_headers: [
        { text: "PO Number", value: "po_number", sortable: true},
        { text: "PO Date", value: "po_date_sortable", sortable: true},
        { text: "Vendor", value: "vendor_name", sortable: true },
        { text: "Customer", value: "customer_name", sortable: true },
        { text: "PO Internal Notes", value: "internal_notes", sortable: false}
      ],
      recently_completed_drop_ships_headers: [
        { text: "PO Number", value: "po_number", sortable: true},
        { text: "PO Date", value: "po_date_sortable", sortable: true},
        { text: "PO Completed Date", value: "po_completed_date_sortable", sortable: true},
        { text: "Vendor", value: "vendor_name", sortable: true },
        { text: "Customer", value: "customer_name", sortable: true },
        { text: "PO Internal Notes", value: "internal_notes", sortable: false}        
      ],
      open_white_glove_headers: [
        { text: "Sales Order Number", value: "sales_order_number", sortable: true},
        { text: "Customer", value: "customer_name", sortable: true },
        { text: "Sales Order Date", value: "order_date_sortable", sortable: true},
        { text: "White Glove Description", value: "white_glove_support_description", sortable: true},
        { text: "Complete Order Status", value: "complete_order_status", sortable: true },
        { text: "Sales Order Internal Notes", value: "internal_notes", sortable: false}
      ],
      recently_completed_white_glove_headers: [
        { text: "Sales Order Number", value: "sales_order_number", sortable: true},
        { text: "Customer", value: "customer_name", sortable: true },
        { text: "Sales Order Date", value: "order_date_sortable", sortable: true},
        { text: "Completed Date", value: "completed_date_sortable", sortable: true},
        { text: "White Glove Description", value: "white_glove_support_description", sortable: true},
        { text: "Complete Order Status", value: "complete_order_status", sortable: true },
        { text: "Sales Order Internal Notes", value: "internal_notes", sortable: false}
      ],
      open_shipments_headers: [
        { text: "Sales Order Number", value: "sales_order_number", sortable: true},
        { text: "Customer", value: "customer_name", sortable: true },
        { text: "Sales Order Date", value: "order_date_sortable", sortable: true},
        { text: "Shipping Status", value: "status", sortable: true},
        { text: "Ship Date", value: "ship_date_sortable", sortable: true},
        { text: "Ship To City", value: "ship_to_city", sortable: true},
        { text: "Shipping Company", value: "shipping_company", sortable: true},
        { text: "Tracking Number", value: "tracking_number", sortable: true},
        { text: "Sales Order ETA", value: "sales_order_eta_sortable", sortable: true},
        { text: "Sales Order ETA Last Updated", value: "sales_order_eta_last_updated_sortable", sortable: true},
        { text: "Sales Order Internal Notes", value: "internal_notes", sortable: true}
      ],
      recently_closed_shipments_headers: [
        { text: "Sales Order Number", value: "sales_order_number", sortable: true},
        { text: "Customer", value: "customer_name", sortable: true },
        { text: "Sales Order Date", value: "order_date_sortable", sortable: true},
        { text: "Shipment Closed Date", value: "shipping_status_complete_timestamp_sortable", sortable: true},
        { text: "Ship Date", value: "ship_date_sortable", sortable: true},
        { text: "Ship To City", value: "ship_to_city", sortable: true},
        { text: "Shipping Company", value: "shipping_company", sortable: true},
        { text: "Tracking Number", value: "tracking_number", sortable: true},
        { text: "Sales Order ETA", value: "sales_order_eta_sortable", sortable: true},
        { text: "Sales Order ETA Last Updated", value: "sales_order_eta_last_updated_sortable", sortable: true},
        { text: "Sales Order Internal Notes", value: "internal_notes", sortable: true}
      ],
      backorder_past_due_missing_headers: [
        { text: "Customer", value: "customer_name", sortable: true },
        { text: "Sales Order Number", value: "order_number", sortable: true },
        { text: "Sales Order Date", value: "order_date_sortable", sortable: true },
        { text: "Product Number", value: "product_number", sortable: true },
        { text: "PO Number", value: "module_po_number", sortable: true },
        { text: "ETA", value: "eta_sortable", sortable: true },
        { text: "ETA Last Updated", value: "eta_last_provided_sortable", sortable: true},
        { text: "Backorder Item Internal Notes", value: "internal_notes", sortable: false}
      ],
      backorder_po_not_open_in_nest_headers: [
        { text: "Customer", value: "customer_name", sortable: true },
        { text: "Sales Order Number", value: "order_number", sortable: true },
        { text: "Sales Order Date", value: "order_date_sortable", sortable: true },
        { text: "Product Number", value: "product_number", sortable: true },
        { text: "PO Number", value: "module_po_number", sortable: true },
        { text: "ETA", value: "eta_sortable", sortable: true },
        { text: "ETA Last Updated", value: "eta_last_provided_sortable", sortable: true},
        { text: "Backorder Item Internal Notes", value: "internal_notes", sortable: false}
      ],      
      backorder_eta_more_than_14_days_headers: [
        { text: "Customer", value: "customer_name", sortable: true },
        { text: "Sales Order Number", value: "order_number", sortable: true },
        { text: "Sales Order Date", value: "order_date_sortable", sortable: true },
        { text: "Product Number", value: "product_number", sortable: true },
        { text: "PO Number", value: "module_po_number", sortable: true },
        { text: "ETA", value: "eta_sortable", sortable: true },
        { text: "ETA Last Updated", value: "eta_last_provided_sortable", sortable: true},
        { text: "Backorder Item Internal Notes", value: "internal_notes", sortable: false}
      ],
      backorder_missing_po_headers: [
        { text: "Customer", value: "customer_name", sortable: true },
        { text: "Sales Order Number", value: "order_number", sortable: true },
        { text: "Sales Order Date", value: "order_date_sortable", sortable: true },
        { text: "Product Number", value: "product_number", sortable: true },
        { text: "Backorder Item Internal Notes", value: "internal_notes", sortable: false}
      ],            
      po_detail: false,
      sales_order_detail: false,
      po_for_component: null,
      sales_order_for_component: null,
      table_height: 200,
    }
  },
  beforeMount(){
    if(this.user === 'vendor' || !this.user){
      this.$router.push('/')
    }
    this.table_height = 400
  },
  created() {
    var self = this
    self.updateNeedsAttentionInterval = setInterval(function(){
      self.refreshKey++
    }, 60000);
    window.addEventListener("resize", this.sizeTable);
  },
  destroyed() {
    window.removeEventListener("resize", this.sizeTable);
  },
  beforeUnmount(){
    
  },
  computed: {
    ...mapGetters(['user','db','po_confirmation_status','item_eta_status']),
    backorders(){
      var backorders_db_clone = this.db.backorders ? JSON.parse(JSON.stringify(this.db.backorders)) : {}

      var backorder_modules_data = {
        past_due_missing_etas: [],
        eta_more_than_14_days: [],
        po_not_open_in_nest: [],
        missing_po: [],
        no_mod: []
      }

      for(var b in backorders_db_clone){
        var backorder = backorders_db_clone[b]
        backorder.eta = 'unknown' // set intially but attempt to find below.
        backorder.id = b
        // (1) linked PO found in NEST and item is found in Nest PO
        // (2) linked PO found in NEST but item NOT found in the Nest PO
        // (3) linked PO but the PO is NOT found/open in Nest
        // (4) no linked PO although was found item in open Nest PO(s) (find the OLDEST DATE po date one and use that info.)
        // (5) no linked PO and could not find item open Nest PO(s)

        if(!backorder.completed){ // we only care about open backorders
          if( this.db.purchase_orders[backorder.linked_po_number] ){
            for(var item_key in this.db.purchase_orders[backorder.linked_po_number].items){
              if(this.db.purchase_orders[backorder.linked_po_number].items[item_key].product === backorder.product_number){
                //(1)
                backorder.eta = this.db.purchase_orders[backorder.linked_po_number].items[item_key].eta
                backorder.eta_last_provided = this.db.purchase_orders[backorder.linked_po_number].items[item_key].eta_last_provided

              }
            }
            //(2)
            if(backorder.eta === 'unknown'){
              backorder.eta = 'Linked PO found but no item match'
            }
          }else{

            if(backorder.linked_po_number){
              //(3)
              backorder.eta = 'Linked PO not open in Nest'
              backorder.po_not_open_in_nest = true
            }

            if(!backorder.linked_po_number){
              var potential_purchase_orders = {}

              for(var po in this.db.purchase_orders){
                if (/[a-zA-Z]/.test(po)) { // Ensure PO is not a special order with a letter
                    continue;
                }              
                for(var item_key in this.db.purchase_orders[po].items){
                  if(this.db.purchase_orders[po].items[item_key].product === backorder.product_number && this.db.purchase_orders[po].items[item_key].line_complete === 'N'){
                    potential_purchase_orders[po] = {
                      po_date: this.db.purchase_orders[po].po_date,
                      po_date_timestamp: Moment(this.db.purchase_orders[po].po_date).startOf('day').valueOf(),
                      eta: this.db.purchase_orders[po].items[item_key].eta,
                      eta_last_provided: this.db.purchase_orders[po].items[item_key].eta_last_provided
                    }
                  }
                }
              }

              if(Object.keys(potential_purchase_orders).length > 0){
                // (4)
                const oldestPO = Object.keys(potential_purchase_orders).reduce((oldest, key) => {
                    return potential_purchase_orders[key].po_date_timestamp < potential_purchase_orders[oldest].po_date_timestamp ? key : oldest;
                    }, Object.keys(potential_purchase_orders)[0]);
                backorder.unlinked_po_number = oldestPO
                backorder.eta = potential_purchase_orders[oldestPO].eta
                backorder.eta_last_provided = potential_purchase_orders[oldestPO].eta_last_provided
                
              }else{
                // (5)
                backorder.unlinked_po_number = 'No PO found'
                backorder.eta = 'No PO found'
                backorder.missing_po = true
              }              
            }
          }

          backorder.module_po_number = backorder.linked_po_number ? backorder.linked_po_number : backorder.unlinked_po_number
          backorder.order_date_sortable = Moment(backorder.order_date).startOf('day').valueOf()
          backorder.order_date_formatted = Moment(backorder.order_date).format('MM/DD/YY');
          backorder.eta_is_date = this.isDateString(backorder.eta)
          backorder.is_white_glove = backorder.white_glove_support_order
          var eta_string_sorting_options = ['','Linked PO found but no item match','Linked PO not open in Nest','No PO found','TBD','UTP']

          if(backorder.eta_is_date){
            backorder.eta_sortable = Moment(backorder.eta).startOf('day').valueOf()
            backorder.eta_formatted = backorder.eta
            backorder.past_due = Moment(backorder.eta).startOf('day').valueOf() < Moment().startOf('day').valueOf()
          }else{
            backorder.eta_sortable = eta_string_sorting_options.indexOf(backorder.eta)
            backorder.eta_formatted = backorder.eta
          }
          
          if(backorder.eta_last_provided){
            backorder.eta_last_provided_sortable = backorder.eta_last_provided
            backorder.eta_last_provided_formatted = Moment(backorder.eta_last_provided).format('MM/DD/YY')
          }else{
            backorder.eta_last_provided_sortable = 0
            backorder.eta_last_provided_formatted = ''
          }

          // PAST DUE OR MISSING ETA
          if((!backorder.eta_is_date && !backorder.missing_po && !backorder.po_not_open_in_nest) || backorder.past_due){
            backorder_modules_data.past_due_missing_etas.push(backorder)
            backorder.in_mod = true
          }

          if(backorder.po_not_open_in_nest){
            backorder_modules_data.po_not_open_in_nest.push(backorder)
            backorder.in_mod = true
          }

          // NO PO
          if(backorder.missing_po){
            var fiveDaysAgoFromNow = Moment().subtract(5, 'days').startOf('day').valueOf();
            
            if (backorder.order_date_sortable < fiveDaysAgoFromNow) {
              backorder.older_than_five_days = true
            }

            backorder_modules_data.missing_po.push(backorder)
            backorder.in_mod = true
          }

          // ETA > 14 days
          if(backorder.eta_is_date){
            var fourteenDaysFromNow = Moment().add(14, 'days').startOf('day').valueOf();
            if (backorder.eta_sortable > fourteenDaysFromNow) {
              backorder_modules_data.eta_more_than_14_days.push(backorder)
              backorder.in_mod = true
            }
          }

          if(!backorder.in_mod){
            backorder_modules_data.no_mod.push(backorder) 
          }
        }
      }
      return backorder_modules_data 
    },
    open_drop_ships(){
      var purchase_orders = JSON.parse(JSON.stringify( this.db.purchase_orders))
      var module_data = []
      for(var id in purchase_orders){
        var po_info = purchase_orders[id]
        var open = po_info.action_po_complete === 'N'
        var first_item = po_info.items[Object.keys(po_info.items)[0]]
        var dropship = first_item.drop_ship
        po_info.customer_name = first_item.ship_to_name
        po_info.po_date_sortable = Moment(po_info.po_date).startOf('day')
        po_info.po_date_formatted = this.formatDate(po_info.po_date_sortable)
        po_info.vendor_page = this.db.vendors['vendor_'+po_info.vendor_number] ? this.db.vendors['vendor_'+po_info.vendor_number].page : ''
        if(open && dropship){
          module_data.push(po_info)
        }
      }
      return module_data
    },
    recently_completed_drop_ships(){
      var purchase_orders = JSON.parse(JSON.stringify( this.db.recently_completed_purchase_orders))
      var module_data = []
      for(var id in purchase_orders){
        var po_info = purchase_orders[id]
        var open = po_info.action_po_complete === 'Y'
        var first_item = po_info.items[Object.keys(po_info.items)[0]]
        var dropship = first_item.drop_ship
        po_info.customer_name = first_item.ship_to_name
        po_info.po_date_sortable = Moment(po_info.po_date).startOf('day')
        po_info.po_date_formatted = this.formatDate(po_info.po_date_sortable)

        po_info.po_completed_date_sortable = Moment(po_info.po_complete_date).startOf('day')
        po_info.po_completed_date_formatted = this.formatDate(po_info.po_completed_date_sortable)

        po_info.vendor_page = this.db.vendors['vendor_'+po_info.vendor_number] ? this.db.vendors['vendor_'+po_info.vendor_number].page : ''
        if(open && dropship){
          module_data.push(po_info)
        }
      }
      return module_data      
    },
    sales_order_data(){
      var module_data = {
        open: [],
        recently_completed: [],
        open_shipments: [],
        recently_closed_shipments: []
      }
      var sales_orders = JSON.parse(JSON.stringify( this.db.sales_orders))
      var sevenDaysAgo = Date.now() - (7 * 24 * 60 * 60 * 1000); // Calculate the timestamp for 7 days ago in milliseconds
      var tenDaysAgo = Date.now() - (10 * 24 * 60 * 60 * 1000); // Calculate the timestamp for 10 days ago in milliseconds

      for(var so in sales_orders){
        var sales_order = sales_orders[so]

        sales_order.order_date_sortable = Moment(sales_order.sales_order_date).startOf('day')
        sales_order.order_date_formatted = this.formatDate(sales_order.order_date_sortable)

        sales_order.ship_date_sortable = sales_order.ship_date ? Moment(sales_order.ship_date).startOf('day') : 999
        sales_order.ship_date_formatted = sales_order.ship_date ? this.formatDate(sales_order.ship_date_sortable) : ''

        sales_order.has_shipping_data = (sales_order.shipping_company && sales_order.shipping_company.trim().length > 0) || (sales_order.tracking_number && sales_order.tracking_number.trim().length > 0)

        sales_order.sales_order_eta_sortable = sales_order.eta ? Moment(sales_order.eta).startOf('day') : 999
        sales_order.sales_order_eta_formatted = sales_order.eta ? this.formatDate(sales_order.sales_order_eta_sortable) : ''

        sales_order.sales_order_eta_last_updated_sortable = sales_order.eta_last_updated ? Moment(sales_order.eta_last_updated).startOf('day') : 999
        sales_order.sales_order_eta_last_updated_formatted = sales_order.eta_last_updated ? this.formatDate(sales_order.sales_order_eta_last_updated_sortable) : ''

        
        if(sales_order.custom_shipping_status){
          sales_order.status = sales_order.custom_shipping_status
        }else{
          sales_order.status = sales_order.ship_date ? 'In Transit' : 'Not Yet Shipped'
        }

        // HERE IS WHERE WE NEED TO FILTER OUT 'CLOSED BY REP'
        if(sales_order.has_shipping_data && sales_order.status !== 'Complete'){
          module_data.open_shipments.push(sales_order)

          // THIS IS TO CLEAR THEM OUT IF NEEDED
          // var self = this
          // firebase.database().ref('sales_orders').child(so).child('custom_shipping_status').set('Complete')
          // firebase.database().ref('sales_orders').child(so).child('shipping_status_complete_timestamp').set(1738180649906)
          // firebase.database().ref('sales_orders').child(so).child('shipping_status_log').push({status: 'Complete', user: self.user, timestamp: 1738180649906})
        }

        // can you close a shipment if no shipment info and then show it in recently closed shipments?
        // set to show only RECENTLY

        if(sales_order.status === 'Complete' && sales_order.shipping_status_complete_timestamp >= tenDaysAgo){


          sales_order.shipping_status_complete_timestamp_sortable = sales_order.shipping_status_complete_timestamp ? Moment(sales_order.shipping_status_complete_timestamp).startOf('day') : 999
          sales_order.shipping_status_complete_timestamp_formatted = sales_order.shipping_status_complete_timestamp ? this.formatDate(sales_order.shipping_status_complete_timestamp_sortable) : ''

          module_data.recently_closed_shipments.push(sales_order)
        }

        if(sales_order.white_glove_support_order){

          if(sales_order.open){
            module_data.open.push(sales_order)
          }
          if(!sales_order.open && sales_order.completed_date >= sevenDaysAgo){
            sales_order.completed_date_sortable = Moment(sales_order.completed_date).startOf('day')
            sales_order.completed_date_formatted = this.formatDate(sales_order.completed_date_sortable) 
            module_data.recently_completed.push(sales_order)
          }
        }

      }
      return module_data
    }
  },
  methods: {
    exportOpenDropships(){
        var items = this.open_drop_ships
        var export_arr = []

        for(var i = 0; i < items.length; i++){
          var item = items[i]
          var row_item = {
            'PO Number' : item.po_number,
            'PO Date': item.po_date_formatted,
            'Vendor': item.vendor_name,
            'Customer': item.customer_name,
            'Internal Notes': ''
          }

          for(var n in item.internal_notes){
            row_item['Internal Notes'] += item.internal_notes[n].note + ' (' + item.internal_notes[n].submitted_by + ' on ' + this.formatDate(n.submitted_date) + '), '
          }
          export_arr.push(row_item)
        }

        var wscols = [
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:100},
        ];

        var worksheet = XLSX.utils.json_to_sheet(export_arr)
        worksheet['!cols'] = wscols;
        var workbook = XLSX.utils.book_new()
        var export_date = Date.now()
        XLSX.utils.book_append_sheet(workbook, worksheet)
        XLSX.writeFile(workbook, 'Open Drop Ship POs ' + export_date + '.xlsx')

    },
    exportBackorerPastDueMissingEta(){
        var items = this.backorders.past_due_missing_etas
        var export_arr = []

        for(var i = 0; i < items.length; i++){
          var item = items[i]
          var row_item = {
            'White Glove': item.white_glove_support_order ? 'WHITE GLOVE' : '',
            'Customer' : item.customer_name,
            'Sales Order Number': item.order_number,
            'Sales Order Date': item.order_date_formatted,
            'Product Number': item.product_number,
            'PO Number': item.module_po_number,
            'ETA': item.eta_formatted,
            'ETA Last Updated': item.eta_last_provided_formatted,
            'Backorder Item Internal Notes': ''
          }

          for(var n in item.internal_notes){
            row_item['Backorder Item Internal Notes'] += item.internal_notes[n].note + ' (' + item.internal_notes[n].submitted_by_name + ' on ' + this.formatDate(n.submitted_date) + '), '
          }
          export_arr.push(row_item)
        }

        var wscols = [
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:100},
        ];

        var worksheet = XLSX.utils.json_to_sheet(export_arr)
        worksheet['!cols'] = wscols;
        var workbook = XLSX.utils.book_new()
        var export_date = Date.now()
        XLSX.utils.book_append_sheet(workbook, worksheet)
        XLSX.writeFile(workbook, 'Backorder Past Due or Missing ETAs ' + export_date + '.xlsx')
    },
    openPurchaseOrderDetailPopup(po){
      this.po_for_component = po
      this.po_detail = true
    },
    closePurchaseOrderDetailPopup(){
      this.po_for_component = null
      this.po_detail = false
    },    
    openSalesOrderDetailPopup(sales_order){
      this.sales_order_for_component = sales_order
      this.sales_order_detail = true
    },
    closeSalesOrderDetailPopup(){
      this.sales_order_for_component = null
      this.sales_order_detail = false
    },
    isDateString(str){
      if (typeof str !== 'string') return false;
      return (str.match(/\//g) || []).length === 2;      
    },
    sizeTable(){
      var wh = window.innerHeight;
      this.table_height = wh - 260
    },
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    },
    formatDateTime(date){
      return Moment(date).format('MM/DD/YY hh:mma')
    }
  }
}
</script>

<style scoped>

  .v-card .v-card-title{
    color: gray;
    font-size: 14px;
  }

  .v-card .v-card-text {
    line-height: 100%;
  }

  .table-container{
    position: relative;
    top: 5px;
    margin:0px 20px;
  }

  .flagged{
    width: 20px;
    height: 20px;
    border:1px solid #ff7214;
    background: #ff7214;
    cursor: pointer;
  }

  .unflagged{
    width: 20px;
    height: 20px;
    border:1px solid #ff7214;
    cursor: pointer;
  }

  .needs_attention{
    background: red;
    color: white;
    width: 15px;
    height: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 140%;
    font-weight: bold;
    border-radius: 100%;
  }

  a{
    text-decoration: none;
  }

  .v-card-actions{
    min-height: 0px;
    padding: 4px 0px;
  }

  .dashboard-link{
    color:#ff7214;
    cursor:pointer;
    font-weight: bold;
    font-size: 11px;
    /*text-decoration: underline;*/

  }
</style>
