<template>
  <div class="purchasing_dashboard">
    <div style="font-size: 22px; color: gray;padding:10px">Purchasing Dashboard</div>

    <div style="margin:10px">
      <v-row>
        <v-col cols="12">
          <v-card elevation="12">
            <v-card-title>
              <v-btn style="background:#ff7214;color:white;height:15px;font-size:12px;" @click="buildEmailsList()">RUN PO CONFIRMATION REMINDERS AND ETA REQUESTS</v-btn>
            </v-card-title>

            <v-card-text style="font-size:10px">
              LAST RAN: {{formatDateTime(db.last_po_confirmations_and_eta_requests_triggered)}}
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              Self Confirm These POs (Auto-Confirm Vendors & Revised POs)
            </v-card-title>
            <v-card-text>
              <Vue3EasyDataTable
                :headers="self_confirm_po_widget_headers"
                :items="purchase_orders_modules_data.auto_confirm_pending_purchase_orders_table_data"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
              >
                <template #item-needs_attention="item">
                  <div v-if="!item.needs_attention"></div>
                  <div v-if="item.needs_attention" class="needs_attention">!</div>
                </template>
                <template #item-po_number="item">
                  <span class="dashboard-link" @click="openPurchaseOrderDetailPopup(item.po_number)">{{item.po_number}}</span>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%">PO Date: {{item.po_date_formatted}}</div>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%;margin-bottom:2px">Buyer: {{item.buyer_name}}</div>
                </template>
                <template #item-vendor_name="item">
                  <router-link :to="{ name: 'vendor', params: {vendorpageid: item.vendor_page ? item.vendor_page : 'null'}}" target="_blank" class="dashboard-link">{{item.vendor_name}}</router-link>
                </template>
                <template #item-po_date_sortable="item">
                  {{item.po_date_formatted}}
                </template>
                <template #item-internal_notes="item">
                  <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%">
                    <div style="font-style:italic">{{n.note}}</div>
                    <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
                  </div>
                  <v-btn style="background:white;color:#ff7214;height:15px;width:50px;font-size:8px;margin:5px 0px" @click="addNote(item.po_number,'internal')">ADD NOTE</v-btn>
                </template>
                <template #item-confirm_po="item">
                  <v-btn style="background:#ff7214;color:white;height:15px;width:50px;font-size:8px;" @click="openPurchaseOrderConfirmationPopUp(item.po_number)">CONFIRM</v-btn>
                </template>
              </Vue3EasyDataTable>
              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{purchase_orders_modules_data.auto_confirm_pending_purchase_orders_table_data.length}}</div>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              Request Confirmations (New POs)
            </v-card-title>
            <v-card-text>
              <Vue3EasyDataTable
                :headers="not_requested_po_confirmations_widget_headers"
                :items="purchase_orders_modules_data.not_requested_purchase_order_confirmations_table_data"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
              >
                <template #item-po_number="item">
                  <span class="dashboard-link" @click="openPurchaseOrderDetailPopup(item.po_number)">{{item.po_number}}</span>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%">PO Date: {{item.po_date_formatted}}</div>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%;margin-bottom:2px">Buyer: {{item.buyer_name}}</div>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%;margin-bottom:2px">PO Confirmation Email: {{item.vendor_po_confirmation_email}}</div>
                </template>
                <template #item-vendor_name="item">
                  <router-link :to="{ name: 'vendor', params: {vendorpageid: item.vendor_page ? item.vendor_page : 'null'}}" target="_blank" class="dashboard-link">{{item.vendor_name}}</router-link>
                </template>
                <template #item-po_date_sortable="item">
                  {{item.po_date_formatted}}
                </template>
                <template #item-internal_notes="item">
                  <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%">
                    <div style="font-style:italic">{{n.note}}</div>
                    <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
                  </div>
                  <v-btn style="background:white;color:#ff7214;height:15px;width:50px;font-size:8px;margin:5px 0px" @click="addNote(item.po_number,'internal')">ADD NOTE</v-btn>
                </template>
                <template #header-send_confirmation_request="header">
                  <input type="checkbox" @click="selectAll('Not Requested')" v-if="po_confirmation_request_cnt !== not_requested_cnt  && not_requested_cnt !== 0"  />
                  <input type="checkbox" @click="unselectAll('Not Requested')" v-if="po_confirmation_request_cnt === not_requested_cnt && not_requested_cnt !== 0" checked/>
                </template>
                <template #item-send_confirmation_request="item">
                  <input type="checkbox" v-model="not_requested_purchase_orders_send_confirmation_request[item.po_number]" />
                </template>
              </Vue3EasyDataTable>
              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{purchase_orders_modules_data.not_requested_purchase_order_confirmations_table_data.length}}</div>
                <v-spacer></v-spacer>
                <div style="padding-right:20px;text-align:right;line-height:100%">
                  <div style="font-style:italic">{{po_confirmation_request_cnt}} purchase order{{po_confirmation_request_cnt !== 1 ? 's' : ''}} selected</div>
                </div>
                <v-btn style="background:#ff7214;color:white;height:15px;width:50px;font-size:8px;" @click="sendConfirmationRequests('Not Requested')" :disabled="po_confirmation_request_cnt === 0">SEND</v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              Self Confirm These POs (No Vendor Email)
            </v-card-title>
            <v-card-text>
              <Vue3EasyDataTable
                :headers="self_confirm_po_no_vendor_email_widget_headers"
                :items="purchase_orders_modules_data.no_vendor_email_pending_purchase_orders_table_data"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
              >

              <template #item-needs_attention="item">
                  <div v-if="!item.needs_attention"></div>
                  <div v-if="item.needs_attention" class="needs_attention">!</div>
                </template>

                <template #item-po_number="item">
                  <span class="dashboard-link" @click="openPurchaseOrderDetailPopup(item.po_number)">{{item.po_number}}</span>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%">PO Date: {{item.po_date_formatted}}</div>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%;margin-bottom:2px">Buyer: {{item.buyer_name}}</div>
                </template>

                <template #item-vendor_name="item">
                  <router-link :to="{ name: 'vendor', params: {vendorpageid: item.vendor_page ? item.vendor_page : 'null'}}" target="_blank" class="dashboard-link">{{item.vendor_name}}</router-link>
                </template>

                <template #item-po_date_sortable="item">
                  {{item.po_date_formatted}}
                </template>

                <template #item-internal_notes="item">
                  <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%">
                    <div style="font-style:italic">{{n.note}}</div>
                    <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
                  </div>
                  <v-btn style="background:white;color:#ff7214;height:15px;width:50px;font-size:8px;margin:5px 0px" @click="addNote(item.po_number,'internal')">ADD NOTE</v-btn>
                </template>

                <template #item-confirm_po="item">
                  <v-btn style="background:#ff7214;color:white;height:15px;width:50px;font-size:8px;" @click="openPurchaseOrderConfirmationPopUp(item.po_number)">CONFIRM</v-btn>
                </template>
              </Vue3EasyDataTable>
              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{purchase_orders_modules_data.no_vendor_email_pending_purchase_orders_table_data.length}}</div>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              Disputed POs
            </v-card-title>

            <v-card-text>
              <Vue3EasyDataTable
                :headers="disputed_po_widget_headers"
                :items="purchase_orders_modules_data.disputed_purchase_orders_table_data"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
              >
                <template #item-po_number="item">
                  <span class="dashboard-link" @click="openPurchaseOrderDetailPopup(item.po_number)">{{item.po_number}}</span>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%;min-width:80px">PO Date: {{item.po_date_formatted}}</div>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%;margin-bottom:2px">Buyer: {{item.buyer_name}}</div>
                </template>

                <template #item-vendor_name="item">
                  <router-link :to="{ name: 'vendor', params: {vendorpageid: item.vendor_page ? item.vendor_page : 'null'}}" target="_blank" class="dashboard-link">{{item.vendor_name}}</router-link>
                </template>

                <template #item-po_date_sortable="item">
                  {{item.po_date_formatted}}
                </template>

                <template #item-internal_notes="item">
                  <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%">
                    <div style="font-style:italic">{{n.note}}</div>
                    <div style="font-size:10px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
                  </div>
                  <v-btn style="background:white;color:#ff7214;height:15px;width:50px;font-size:8px;margin:5px 0px;" @click="addNote(item.po_number,'internal')">ADD NOTE</v-btn>
                </template>

                <template #item-shared_notes="item">
                  <div v-for="n in item.shared_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%">
                    <div style="font-style:italic">{{n.note}}</div>
                    <div style="font-size:10px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
                  </div>
                  <v-btn style="background:white;color:#ff7214;height:15px;width:50px;font-size:8px;margin:5px 0px" @click="addNote(item.po_number,'shared')">ADD NOTE</v-btn>
                </template>
              </Vue3EasyDataTable>
              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{purchase_orders_modules_data.disputed_purchase_orders_table_data.length}}</div>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              Past Due ETA Items
            </v-card-title>
            <v-card-text>
              <Vue3EasyDataTable
                :headers="past_due_eta_items_headers"
                :items="purchase_orders_modules_data.past_due_eta_items_table_data"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
                :body-item-id-name="bodyItemClassNameFunction"
              >

                <template #item-po_number="item">
                  <span class="dashboard-link" @click="openPurchaseOrderDetailPopup(item.po_number)">{{item.po_number}}</span>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%">PO Date: {{item.po_date_formatted}}</div>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%;margin-bottom:2px">Buyer: {{item.buyer_name}}</div>
                </template>

                <template #item-product="item">
                  <span class="dashboard-link" @click="openItemDetailPopup(item.po_number,item.item_key)">{{item.product}}</span>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%;margin-bottom:2px">{{item.product_description}}</div>
                </template>

                <template #item-vendor_name="item">
                  <router-link :to="{ name: 'vendor', params: {vendorpageid: item.vendor_page ? item.vendor_page : 'null'}}" target="_blank" class="dashboard-link">{{item.vendor_name}}</router-link>
                </template>

                <template #item-eta="item">
                  <span :style="{ textDecoration: item.approx_eta_set ? 'underline' : 'none' }">
                    {{item.eta}}
                  </span>
                </template>

                 <template #item-remaining_lead_time_days="item">
                  <span v-if="item.remaining_lead_time_days === 9999">{{item.eta}}</span>
                  <span v-if="item.remaining_lead_time_days !== 9999">{{ Math.abs(item.remaining_lead_time_days) }} Days Past Due</span>
                </template>

                <template #item-internal_notes="item">
                  <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%">
                    <div style="font-style:italic">{{n.note}}</div>
                    <div style="font-size:10px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
                  </div>
                </template>

                <template #item-shared_notes="item">
                  <div v-for="n in item.shared_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%">
                    <div style="font-style:italic">{{n.note}}</div>
                    <div style="font-size:10px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
                  </div>
                </template>
              </Vue3EasyDataTable>


              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{purchase_orders_modules_data.past_due_eta_items_table_data.length}}</div>
                <v-spacer></v-spacer>
                <v-btn style="background:#ff7214;color:white;height:15px;width:50px;font-size:8px;" @click="exportPastDueItems()" :disabled="purchase_orders_modules_data.past_due_eta_items_table_data.length === 0">EXPORT</v-btn>
              </v-card-actions>

            </v-card-text>
          </v-card>
        </v-col>
        
        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              Escalated PO Confirmation Requests
            </v-card-title>
            <v-card-text>
              <Vue3EasyDataTable
                :headers="escalation_po_confirmation_requests_widget_headers"
                :items="purchase_orders_modules_data.escalation_po_confirmation_requests_table_data"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
              >
                <template #item-po_number="item">
                  <span class="dashboard-link" @click="openPurchaseOrderDetailPopup(item.po_number)">{{item.po_number}}</span>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%">PO Date: {{item.po_date_formatted}}</div>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%;margin-bottom:2px">Buyer: {{item.buyer_name}}</div>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%;margin-bottom:2px">PO Confirmation Email: {{item.vendor_po_confirmation_email}}</div>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%;margin-bottom:2px">Escalation Email: {{item.vendor_escalation_email}}</div>
                </template>
                <template #item-vendor_name="item">
                  <router-link :to="{ name: 'vendor', params: {vendorpageid: item.vendor_page ? item.vendor_page : 'null'}}" target="_blank" class="dashboard-link">{{item.vendor_name}}</router-link>
                </template>
                <template #item-po_date_sortable="item">
                  {{item.po_date_formatted}}
                </template>

                <template #item-po_confirmation_last_requested_sortable="item">
                  <div>{{item.po_confirmation_last_requested_formatted}}</div>
                  <v-btn style="background:#ff7214;color:white;height:15px;width:80px;font-size:8px;" @click="requestPoConfirmationAgain(item.vendor_number,item.po_number)">REQUEST AGAIN</v-btn>
                </template>

                <template #item-internal_notes="item">
                  <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%">
                    <div style="font-style:italic">{{n.note}}</div>
                    <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
                  </div>
                  <v-btn style="background:white;color:#ff7214;height:15px;width:50px;font-size:8px;margin:5px 0px" @click="addNote(item.po_number,'internal')">ADD NOTE</v-btn>
                </template>

              </Vue3EasyDataTable>

              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{purchase_orders_modules_data.escalation_po_confirmation_requests_table_data.length}}</div>
                <v-spacer></v-spacer>
                <v-btn style="background:#ff7214;color:white;height:15px;width:50px;font-size:8px;" @click="exportEscaltedPoRequests()" :disabled="purchase_orders_modules_data.escalation_po_confirmation_requests_table_data.length === 0">EXPORT</v-btn>
              </v-card-actions>

            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              PO Items Flagged Critical
            </v-card-title>
            <v-card-text>
              <Vue3EasyDataTable
                :headers="flagged_critical_po_items_headers"
                :items="purchase_orders_modules_data.items_flagged_critical_table_data"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
              >

                <template #item-flag_critical="item">
                  <!-- <div v-if="!item.flag_critical" @click="flagCritical(item.po_number,item.item_key)" class="unflagged"></div> -->
                  <div v-if="item.flag_critical" @click="unflagCritical(item.po_number,item.item_key)" class="flagged"></div>
                </template>

                <template #item-po_number="item">
                  <span class="dashboard-link" @click="openPurchaseOrderDetailPopup(item.po_number)">{{item.po_number}}</span>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%">PO Date: {{item.po_date_formatted}}</div>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%;margin-bottom:2px">Buyer: {{item.buyer_name}}</div>
                </template>

                <template #item-eta_last_provided_sortable="item">
                  {{item.eta_last_provided_formatted}}
                </template>

                <template #item-product="item">
                  <span class="dashboard-link" @click="openItemDetailPopup(item.po_number,item.item_key)">{{item.product}}</span>
                </template>

                <template #item-vendor_name="item">
                  <router-link :to="{ name: 'vendor', params: {vendorpageid: item.vendor_page ? item.vendor_page : 'null'}}" target="_blank" class="dashboard-link">{{item.vendor_name}}</router-link>
                </template>

                <template #item-eta="item">
                  <span :style="{ textDecoration: item.approx_eta_set ? 'underline' : 'none' }">
                    {{item.eta}}
                  </span>
                </template>

              </Vue3EasyDataTable>

              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{purchase_orders_modules_data.items_flagged_critical_table_data.length}}</div>
                <v-spacer></v-spacer>
                <v-btn style="background:#ff7214;color:white;height:15px;width:50px;font-size:8px;" @click="exportFlaggedCritical()" :disabled="purchase_orders_modules_data.items_flagged_critical_table_data.length === 0">EXPORT</v-btn>
              </v-card-actions>

            </v-card-text>
          </v-card>
        </v-col>

        <!-- Backorders Flagged Critical -->
        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              Backorders Flagged Critical
            </v-card-title>
            <v-card-text>
              <Vue3EasyDataTable
                :headers="flagged_critical_backorders_headers"
                :items="backorders_flagged_critical_data"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
              >

              <template #item-customer_name="item">
                <router-link :to="{ name: 'customer', params: {customerpageid: db.customers['customer_'+item.customer_number].page}}" target="_blank" class="dashboard-link">{{item.customer_name}}</router-link>
              </template>

              <template #item-linked_po_number="item">
                <span v-if="item.po_for_detail" class="dashboard-link" @click="openPurchaseOrderDetailPopup(item.po_for_detail)">{{item.linked_po_number}}</span>
                <span v-if="!item.po_for_detail">{{item.linked_po_number}}</span>
              </template>

              <template #item-sortable_eta="item">

                <div v-if="item.non_current_eta && item.approx_eta_set" style="background:red;color:white;font-weight:bold;padding:4px;text-decoration:underline">{{item.eta}}</div>
                <div v-if="item.non_current_eta && !item.approx_eta_set" style="background:red;color:white;font-weight:bold;padding:4px;">{{item.eta}}</div>

                <div v-if="!item.non_current_eta" style="padding:4px">
                  <div v-if="item.approx_eta_set" style="text-decoration:underline">{{item.eta}}</div>
                  <div v-if="!item.approx_eta_set">{{item.eta}}</div>
                </div>
              </template>

          <template #item-internal_notes="item">
            <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%;min-width:100px">
              <div style="font-style:italic">{{n.note}}</div>
              <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by_name}} {{n.submitted_by_email}}</div>
            </div>
            <v-btn style="background:white;color:#ff7214;height:15px;width:50px;font-size:8px;margin:5px 0px" @click="backorderNote('internal',item.id)">ADD NOTE</v-btn>
          </template>

              </Vue3EasyDataTable>

              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{backorders_flagged_critical_data.length}}</div>
                <v-spacer></v-spacer>
                <!-- <v-btn style="background:#ff7214;color:white;height:15px;width:50px;font-size:8px;" @click="exportFlaggedCritical()" :disabled="purchase_orders_modules_data.items_flagged_critical_table_data.length === 0">EXPORT</v-btn> -->
              </v-card-actions>

            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              Delayed Items (ETA 30+ days out, TBD or UTP)
            </v-card-title>
            <v-card-text>
              <Vue3EasyDataTable
                :headers="delayed_utp_tbd_items_headers"
                :items="purchase_orders_modules_data.delayed_eta_utp_tbd_items_table_data"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
                :body-item-id-name="bodyItemClassNameFunction"
              >

                <template #item-po_number="item">
                  <span class="dashboard-link" @click="openPurchaseOrderDetailPopup(item.po_number)">{{item.po_number}}</span>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%">PO Date: {{item.po_date_formatted}}</div>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%;margin-bottom:2px">Buyer: {{item.buyer_name}}</div>
                </template>

                <template #item-product="item">
                  <span class="dashboard-link" @click="openItemDetailPopup(item.po_number,item.item_key)">{{item.product}}</span>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%;margin-bottom:2px">{{item.product_description}}</div>
                </template>

                <template #item-vendor_name="item">
                  <router-link :to="{ name: 'vendor', params: {vendorpageid: item.vendor_page ? item.vendor_page : 'null'}}" target="_blank" class="dashboard-link">{{item.vendor_name}}</router-link>
                </template>

                <template #item-eta="item">
                  <span :style="{ textDecoration: item.approx_eta_set ? 'underline' : 'none' }">
                    {{item.eta}}
                  </span>
                </template>

                 <template #item-remaining_lead_time_days="item">
                  <span v-if="item.remaining_lead_time_days === 9999">{{item.eta}}</span>
                  <span v-if="item.remaining_lead_time_days !== 9999">{{ item.remaining_lead_time_days }} Days</span>
                </template>


                <template #item-internal_notes="item">
                  <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%">
                    <div style="font-style:italic">{{n.note}}</div>
                    <div style="font-size:10px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
                  </div>
                </template>

                <template #item-shared_notes="item">
                  <div v-for="n in item.shared_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%">
                    <div style="font-style:italic">{{n.note}}</div>
                    <div style="font-size:10px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by}}</div>
                  </div>
                  <!-- <v-btn style="background:white;color:#ff7214;height:15px;width:50px;font-size:8px;margin:5px 0px" @click="addNote(item.po_number,'shared')">ADD NOTE</v-btn> -->
                </template>
              </Vue3EasyDataTable>


              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{purchase_orders_modules_data.delayed_eta_utp_tbd_items_table_data.length}}</div>
                <v-spacer></v-spacer>
                <v-btn style="background:#ff7214;color:white;height:15px;width:50px;font-size:8px;" @click="exportDelayedItems()" :disabled="purchase_orders_modules_data.delayed_eta_utp_tbd_items_table_data.length === 0">EXPORT</v-btn>
              </v-card-actions>

            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              Self Track These ETAs
            </v-card-title>
            <v-card-text>
              <Vue3EasyDataTable
                :headers="self_track_eta_items_headers"
                :items="purchase_orders_modules_data.self_track_eta_items_table_data"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
              >

                <template #item-po_number="item">
                  <span class="dashboard-link" @click="openPurchaseOrderDetailPopup(item.po_number)">{{item.po_number}}</span>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%">PO Date: {{item.po_date_formatted}}</div>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%;margin-bottom:2px">Buyer: {{item.buyer_name}}</div>

                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%;margin-bottom:2px">ETA Inquiry Email: {{item.eta_inquiry_email}}</div>
                </template>

                <template #item-product="item">
                  <span class="dashboard-link" @click="openItemDetailPopup(item.po_number,item.item_key)">{{item.product}}</span>
                </template>

                <template #item-vendor_name="item">
                  <router-link :to="{ name: 'vendor', params: {vendorpageid: item.vendor_page ? item.vendor_page : 'null'}}" target="_blank" class="dashboard-link">{{item.vendor_name}}</router-link>
                </template>

                <template #item-eta="item">
                  <span :style="{ textDecoration: item.approx_eta_set ? 'underline' : 'none' }">
                    {{item.eta}}
                  </span>
                </template>
                
                 <template #item-remaining_lead_time_days="item">
                  <span v-if="item.remaining_lead_time_days === 9999"></span>
                  <span v-if="item.remaining_lead_time_days !== 9999 && item.remaining_lead_time_days === 1">{{item.remaining_lead_time_days + ' Day'}}</span>
                  <span v-if="item.remaining_lead_time_days !== 9999 && item.remaining_lead_time_days > 1">{{item.remaining_lead_time_days + ' Days'}}</span>
                  <span v-if="item.remaining_lead_time_days !== 9999 && item.remaining_lead_time_days === 0">Due Today</span>
                  <span v-if="item.remaining_lead_time_days !== 9999 && item.remaining_lead_time_days === -1" style="color:red">{{ Math.abs(item.remaining_lead_time_days) + ' Day Past Due'}}</span>
                  <span v-if="item.remaining_lead_time_days !== 9999 && item.remaining_lead_time_days < -1" style="color:red">{{ Math.abs(item.remaining_lead_time_days) + ' Days Past Due'}}</span>
                </template>


              </Vue3EasyDataTable>

              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{purchase_orders_modules_data.self_track_eta_items_table_data.length}}</div>
                <v-spacer></v-spacer>
                <v-btn style="background:#ff7214;color:white;height:15px;width:50px;font-size:8px;" @click="exportSelfTrackEtas()" :disabled="purchase_orders_modules_data.self_track_eta_items_table_data.length === 0">EXPORT</v-btn>
              </v-card-actions>

            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              Product on Multiple POs
            </v-card-title>
            <v-card-text>
              <Vue3EasyDataTable
                :headers="items_multiple_pos_headers"
                :items="purchase_orders_modules_data.items_on_multiple_purchase_orders_table_data"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
              >

                <template #item-detail="item">
                  <div v-for="p in item.detail" style="padding-bottom:2px">
                    <table>
                      <tr style="line-height:100%"><span class="dashboard-link" @click="openPurchaseOrderDetailPopup(p.po_number)">{{p.po_number}}</span></tr>
                      <tr style="font-style:italic;font-size:10px;color:gray;line-height:100%"><span>{{p.vendor_name}}</span></tr>
                      <tr style="font-style:italic;font-size:10px;color:gray;line-height:100%"><span>ETA: {{p.eta}}</span></tr>
                    </table>
                  </div>
                </template>
              </Vue3EasyDataTable>

              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{purchase_orders_modules_data.items_on_multiple_purchase_orders_table_data.length}}</div>
                <v-spacer></v-spacer>

                <v-btn style="background:#ff7214;color:white;height:15px;width:50px;font-size:8px;" @click="exportProductMultiplePOs()" :disabled="purchase_orders_modules_data.items_on_multiple_purchase_orders_table_data.length === 0">EXPORT</v-btn>                
              </v-card-actions>

            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card height="350px" elevation="12">
            <v-card-title>
              Vendor Not Responding to ETA Requests After Multiple Attempts
            </v-card-title>
            <v-card-text>
              <Vue3EasyDataTable
                :headers="escalated_eta_requests_widget_headers"
                :items="purchase_orders_modules_data.escalated_eta_requests_table_data"
                :rows-per-page="2000"
                :hide-rows-per-page="true"
                :hide-footer="true"
                :table-height="275"
              >

                <template #item-po_number="item">
                  <span class="dashboard-link" @click="openPurchaseOrderDetailPopup(item.po_number)">{{item.po_number}}</span>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%">PO Date: {{item.po_date_formatted}}</div>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%;margin-bottom:2px">Buyer: {{item.buyer_name}}</div>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%;margin-bottom:2px">ETA Inquiry Email: {{item.eta_inquiry_email}}</div>
                  <div style="font-style:italic;font-size:10px;color:gray;line-height:100%;margin-bottom:2px">Escalation Email: {{item.vendor_escalation_email}}</div>
                </template>

                <template #item-product="item">
                  <span class="dashboard-link" @click="openItemDetailPopup(item.po_number,item.item_key)">{{item.product}}</span>
                </template>

                <template #item-vendor_name="item">
                  <router-link :to="{ name: 'vendor', params: {vendorpageid: item.vendor_page ? item.vendor_page : 'null'}}" target="_blank" class="dashboard-link">{{item.vendor_name}}</router-link>
                </template>

                <template #item-eta_last_requested_sortable="item">
                  {{item.eta_last_requested_formatted}}
                </template>

              </Vue3EasyDataTable>

              <v-card-actions>
                <div style="color:gray;font-size:10px">Record Count: {{purchase_orders_modules_data.escalated_eta_requests_table_data.length}}</div>
                <v-spacer></v-spacer>
                <v-btn style="background:#ff7214;color:white;height:15px;width:50px;font-size:8px;" @click="exportVendorNotRespondingToEtaRequests()" :disabled="purchase_orders_modules_data.escalated_eta_requests_table_data.length === 0">EXPORT</v-btn>
              </v-card-actions>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </div>

    <PurchaseOrderDetailPopUp v-if="po_detail" :po='po_for_component' @close="closePurchaseOrderDetailPopup()" />
    <ItemDetailPopUp v-if="item_detail" :po='po_for_component' :itemkey='item_for_component' @close="closeItemDetailPopup()" />
    <PurchaseOrderConfirmationPopUp v-if="show_po_confirmation_popup" :po='po_for_component' :vendor='vendor_info_for_component' @close="closePurchaseOrderConfirmationPopUp()" />
    <NotePurchaseOrderPopUp v-if="note_popup" @close="note_popup = false" :po="po_for_component" :type="note_type"/>
    <BackorderNotePopUp v-if="backorder_note_popup" @close="backorder_note_popup = false" :backorderkey="backorder_key_for_component" :type="backorder_note_type"/>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment-business-days'
import _ from 'lodash'
import PurchaseOrderConfirmationPopUp from '@/components/PurchaseOrderConfirmationPopUp.vue'
import ItemDetailPopUp from '@/components/ItemDetailPopUp.vue'
import PurchaseOrderDetailPopUp from '@/components/PurchaseOrderDetailPopUp.vue'
import NotePurchaseOrderPopUp from '@/components/NotePurchaseOrderPopUp.vue'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import BackorderNotePopUp from '@/components/BackorderNotePopUp.vue'
import { emailMixin } from '../mixins/emailMixin.js'
import XLSX from 'xlsx'
import { poConfirmationFollowupEtaRequest } from '../mixins/poConfirmationFollowupEtaRequestMixin.js'

export default {
  name: 'PurchasingDashboard',
  components: {PurchaseOrderConfirmationPopUp,PurchaseOrderDetailPopUp,ItemDetailPopUp,Vue3EasyDataTable,NotePurchaseOrderPopUp,BackorderNotePopUp},
  mixins: [emailMixin, poConfirmationFollowupEtaRequest],
  data(){
    return {
      tab: 'TO-DOS',
      po_detail: false,
      item_detail: false,
      po_for_component: null,
      vendor_info_for_component: null,
      item_for_component: null,
      eta_request: false,
      searchValuePurchaseOrders: '',
      searchValueItems: '',
      includeCompletedPurchaseOrders: false,
      onlyNotRequestedConfirmationPurchaseOrders: false,
      onlyPastDueItems: false,
      includeCompletedItems: false,
      refreshKey: 0,
      show_po_confirmation_popup: false,
      note_popup: false,
      note_type: null,
      not_requested_purchase_orders_send_confirmation_request: {},
      not_requested_cnt: 0,
      backorder_note_popup: false,
      past_due_eta_items_headers: [
        { text: "PO Number", value: "po_number", sortable: true},
        { text: "RH Product #", value: "product", sortable: true},
        { text: "Vendor", value: "vendor_name", sortable: true },
        { text: "Item ETA", value: "eta", sortable: true},
        { text: "Days Past Due", value: "remaining_lead_time_days", sortable: true },
        { text: "Internal Notes", value: "internal_notes", sortable: true},
        { text: "Shared Notes", value: "shared_notes", sortable: true},
      ],

      self_confirm_po_widget_headers: [
        { text: "", value: "needs_attention", sortable: true },
        { text: "PO Number", value: "po_number", sortable: true },
        { text: "Revised PO", value: "revised", sortable: true },
        { text: "Vendor", value: "vendor_name", sortable: true },
        { text: "Internal Notes", value: "internal_notes", sortable: false },
        { text: "", value: "confirm_po", sortable: false },
      ],
      self_confirm_po_no_vendor_email_widget_headers: [
        { text: "", value: "needs_attention", sortable: true },
        { text: "PO Number", value: "po_number", sortable: true },
        { text: "Vendor", value: "vendor_name", sortable: true },
        { text: "Internal Notes", value: "internal_notes", sortable: false },
        { text: "", value: "confirm_po", sortable: false },
      ],      
      not_requested_po_confirmations_widget_headers: [
        { text: "PO Number", value: "po_number", sortable: true },
        { text: "Vendor", value: "vendor_name", sortable: true },
        { text: "Internal Notes", value: "internal_notes", sortable: false },
        { text: "", value: "send_confirmation_request", sortable: false },
      ],
      disputed_po_widget_headers: [
        { text: "PO Number", value: "po_number", sortable: true },
        { text: "Vendor", value: "vendor_name", sortable: true },
        { text: "Internal Notes", value: "internal_notes", sortable: false },
        { text: "Shared Notes", value: "shared_notes", sortable: false }
      ],
      escalation_po_confirmation_requests_widget_headers: [
        { text: "PO Number", value: "po_number", sortable: true },
        { text: "Vendor", value: "vendor_name", sortable: true },
        { text: "Last Requested", value: "po_confirmation_last_requested_sortable", sortable: true },
        { text: "Requested Count", value: "po_confirmation_requested_cnt", sortable: true },
        { text: "Internal Notes", value: "internal_notes", sortable: false }
      ],
      delayed_utp_tbd_items_headers: [
        { text: "PO Number", value: "po_number", sortable: true},
        { text: "RH Product #", value: "product", sortable: true},
        { text: "Vendor", value: "vendor_name", sortable: true },
        { text: "Item ETA", value: "eta", sortable: true},
        { text: "Remaining Lead Time", value: "remaining_lead_time_days", sortable: true },
        { text: "Internal Notes", value: "internal_notes", sortable: true},
        { text: "Shared Notes", value: "shared_notes", sortable: true},
      ],
      self_track_eta_items_headers: [
        { text: "PO Number", value: "po_number", sortable: true},
        { text: "RH Product #", value: "product", sortable: true},
        { text: "Vendor", value: "vendor_name", sortable: true },

        { text: "Dropship?", value: "dropship", sortable: true },

        { text: "Item ETA", value: "eta", sortable: true},

        { text: "Qty Not Yet Received", value: "quantity_backordered", sortable: true},

        { text: "Remaining Lead Time", value: "remaining_lead_time_days", sortable: true },
      ],
      flagged_critical_po_items_headers: [
        { text: "Flag Critical", value: "flag_critical", sortable: false},
        { text: "PO Number", value: "po_number", sortable: true},
        { text: "RH Product #", value: "product", sortable: true},
        { text: "Vendor", value: "vendor_name", sortable: true },
        { text: "ETA Last Updated", value: "eta_last_provided_sortable", sortable: true },
        { text: "Item ETA Status", value: "eta_status", sortable: true},
        { text: "Item ETA", value: "eta", sortable: true}
      ],
      flagged_critical_backorders_headers: [
        // { text: "id", value: "id", sortable: true },
        { text: "Riverhawk Product Number", value: "product_number", sortable: true },
        { text: "Riverhawk Sales Order Number", value: "order_number", sortable: true },
        { text: "Customer Name", value: "customer_name", sortable: true },
        { text: "Order Writer", value: "order_writer_name", sortable: true },
        { text: "ETA", value: "sortable_eta", sortable: true },
        { text: "Linked PO Number", value: "linked_po_number", sortable: true },
        { text: "Internal Notes", value: "internal_notes", sortable: false },
      ],
      escalated_eta_requests_widget_headers: [
        { text: "PO Number", value: "po_number", sortable: true},
        { text: "RH Product #", value: "product", sortable: true},
        { text: "Vendor", value: "vendor_name", sortable: true },
        { text: "ETA Requests Count", value: "eta_escalation_requests_cnt", sortable: true},
        { text: "ETA Last Requested", value: "eta_last_requested_sortable", sortable: true }
      ],
      items_multiple_pos_headers: [
        { text: "RH Product #", value: "product", sortable: true},
        { text: "# of POs", value: "cnt", sortable: true},
        { text: "Detail", value: "detail", sortable: false }
      ],
      table_height: 200,
    }
  },
  beforeMount(){
    if(this.user === 'vendor' || !this.user){
      this.$router.push('/')
    }
    this.table_height = 400
  },
  watch: {
    tab(){
      this.sizeTable()
    },
    onlyNotRequestedConfirmationPurchaseOrders(val){
      if(val === true){
        this.includeCompletedPurchaseOrders = false
      }
    },
    includeCompletedPurchaseOrders(val){
      if(val === true){
        this.onlyNotRequestedConfirmationPurchaseOrders = false
      }
    },
    includeCompletedItems(val){
      if(val === true){
        this.onlyPastDueItems = false
      }
    },
    onlyPastDueItems(val){
      if(val === true){
        this.includeCompletedItems = false
      }
    }
  },
  created() {
    var self = this
    self.updateNeedsAttentionInterval = setInterval(function(){
      self.refreshKey++
    }, 60000);
    window.addEventListener("resize", this.sizeTable);
  },
  destroyed() {
    window.removeEventListener("resize", this.sizeTable);
  },
  beforeUnmount(){
    clearInterval( this.updateNeedsAttentionInterval )
  },
  computed: {
    ...mapGetters(['user','db','po_confirmation_status','item_eta_status']),
    po_confirmation_request_cnt(){
      var cnt = 0
      for(var po in this.not_requested_purchase_orders_send_confirmation_request){
        if(this.not_requested_purchase_orders_send_confirmation_request[po]){
          cnt++
        }
      }
      return cnt
    },
    current_hour(){
      this.refreshKey;
      var t = Moment().format('HH')
      return parseFloat(t)
    },
    backorders_flagged_critical_data(){

      var backorders_db_clone = this.db.backorders ? JSON.parse(JSON.stringify(this.db.backorders)) : {}
      var backorders = []

      for(var b in backorders_db_clone){
        var backorder = backorders_db_clone[b]
        backorder.id = b
        backorder.missing_linked_po_number = !backorder.linked_po_number || backorder.linked_po_number.trim() === ""
        if(!backorder.completed && backorder.critical_item){
          backorder.dropship = backorder.line_item_warehouse === '09' ? 'YES' : 'NO'
          backorder.critical_item = backorder.critical_item ? true : false
          
          if(this.db.purchase_orders[backorder.linked_po_number] ){
            backorder.po_for_detail = backorder.linked_po_number
            backorder.vendor = this.db.purchase_orders[backorder.linked_po_number].vendor_name
            backorder.vendor_number = this.db.purchase_orders[backorder.linked_po_number].vendor_number
            backorder.vendor_page = this.db.vendors['vendor_'+backorder.vendor_number].page
            backorder.eta = 'Item Not Found In Nest PO'
            backorder.non_current_eta = false
            backorder.has_linked_po = true

            for(var item_key in this.db.purchase_orders[backorder.linked_po_number].items){

              if(this.db.purchase_orders[backorder.linked_po_number].items[item_key].product === backorder.product_number){
                backorder.eta = this.db.purchase_orders[backorder.linked_po_number].items[item_key].eta
                
                if(this.db.purchase_orders[backorder.linked_po_number].items[item_key].line_complete === 'Y'){
                  backorder.eta = 'Complete'
                }

                if(this.db.purchase_orders[backorder.linked_po_number].items[item_key].approx_eta_set){
                  backorder.approx_eta_set = true
                }

                if(backorder.eta === 'UTP'){
                  backorder.eta = 'Vendor Unable To Provide Date'
                }

                if(backorder.eta === 'TBD'){
                  backorder.eta = 'ETA TBD'
                }

                if(backorder.eta === ''){
                  backorder.eta = this.item_eta_status(this.db.purchase_orders[backorder.linked_po_number],item_key)
                }
              }

            }

          }else{

            if(!backorder.linked_po_number){
                var potential_purchase_orders = {};
                
                for(var po in this.db.purchase_orders){
                    if (/[a-zA-Z]/.test(po)) { // Ensure PO is not a special order with a letter
                        continue;
                    }
                    
                    for(var item_key in this.db.purchase_orders[po].items){
                        let item = this.db.purchase_orders[po].items[item_key];
                        if(item.product === backorder.product_number && item.line_complete !== 'Y'){
                            potential_purchase_orders[po] = {
                                po_date: this.db.purchase_orders[po].po_date,
                                po_date_timestamp: Moment(this.db.purchase_orders[po].po_date).startOf('day').valueOf(),
                                eta: item.eta,
                                eta_last_provided: item.eta_last_provided,
                                vendor_name: this.db.purchase_orders[po].vendor_name,
                                vendor_number: this.db.purchase_orders[po].vendor_number,
                                vendor_page: this.db.vendors['vendor_' + this.db.purchase_orders[po].vendor_number]?.page
                            };
                        }
                    }
                }
                
                if(Object.keys(potential_purchase_orders).length > 0){
                    const oldestPO = Object.keys(potential_purchase_orders).reduce((oldest, key) => {
                        return potential_purchase_orders[key].po_date_timestamp < potential_purchase_orders[oldest].po_date_timestamp ? key : oldest;
                    }, Object.keys(potential_purchase_orders)[0]);

                    let selectedPO = potential_purchase_orders[oldestPO];
                    let eta = selectedPO.eta;
                    let po = oldestPO;
                    let vendor = selectedPO.vendor_name;
                    let vendor_number = selectedPO.vendor_number;
                    let vendor_page = selectedPO.vendor_page;
                    
                    if (eta === '' || eta === 'UTP' || eta === 'TBD') {
                        backorder.eta = eta === 'UTP' ? 'Vendor Unable To Provide Date' : eta === 'TBD' ? 'ETA TBD' : 'Pending ETA From Vendor';
                        backorder.linked_po_number = po + ' *';
                        backorder.vendor = vendor + ' *';
                        backorder.vendor_number = vendor_number;
                        backorder.vendor_page = vendor_page;
                        backorder.po_for_detail = po;
                        backorder.non_current_eta = true;
                    } else {
                        backorder.eta = Moment(eta, 'M/DD/YY').format('M/DD/YY');
                        backorder.linked_po_number = po + ' *';
                        backorder.vendor = vendor + ' *';
                        backorder.vendor_number = vendor_number;
                        backorder.vendor_page = vendor_page;
                        backorder.po_for_detail = po;
                    }
                } else {
                    backorder.eta = this.user !== 'vendor' ? 'No PO Found' : 'PO Conf Pending';
                    backorder.linked_po_number = this.user !== 'vendor' ? 'No PO Found' : 'PO Conf Pending';
                    backorder.vendor = this.user !== 'vendor' ? 'No PO Found' : 'PO Conf Pending';
                    backorder.vendor_number = false;
                    backorder.vendor_page = false;
                    backorder.po_for_detail = false;
                    backorder.missing_po = true;
                    backorder.non_current_eta = true;
                }
            }

            if(!backorder.missing_linked_po_number){
              backorder.eta = 'Linked PO not open in Nest'
              backorder.vendor = 'Linked PO not open in Nest'
            }
          }

          if (Moment(backorder.eta, 'M/DD/YY').valueOf() < Moment().startOf('day').valueOf()) {
            backorder.eta = '(' + backorder.eta + ') Past Due, Update Pending';
            backorder.non_current_eta = true
          }

          if(backorder.eta == 'PO Conf Pending' || backorder.eta == 'Vendor Unable To Provide Date' || backorder.eta == 'Not Requested' || backorder.eta == 'PO Disputed'){
            backorder.non_current_eta = true
          }

          if(!backorder.has_linked_po && this.user !== 'vendor' && backorder.eta !== 'Linked PO not open in Nest'){
            backorder.eta = backorder.eta + ' *'
          }

          backorder.sortable_eta = Moment(this.parseETA(backorder.eta)).startOf('day')

          backorders.push(backorder);
        }
      }
      return backorders
    },
    purchase_orders_modules_data(){
      var purchase_orders = JSON.parse(JSON.stringify( this.db.purchase_orders))
      var modules_data = {
        no_vendor_email_pending_purchase_orders_table_data: [],
        auto_confirm_pending_purchase_orders_table_data: [],
        not_requested_purchase_order_confirmations_table_data: [],
        disputed_purchase_orders_table_data: [],
        escalation_po_confirmation_requests_table_data: [],
        delayed_eta_utp_tbd_items_table_data: [],
        past_due_eta_items_table_data: [],
        items_flagged_critical_table_data: [],
        self_track_eta_items_table_data: [],
        escalated_eta_requests_table_data: [],
        items_on_multiple_purchase_orders_table_data: []
      }

      var current = Moment(Date.now()).startOf('day')
      var purchase_order_numbers_not_requested = []
      var items_multiple_pos_cnt = {}
      this.not_requested_cnt = 0

      for(var po in purchase_orders){
        var po_start = Moment(purchase_orders[po].po_date).startOf('day')
        purchase_orders[po].upload_by = this.db.users[purchase_orders[po].upload_by].email
        purchase_orders[po].po_confirmation_status = this.po_confirmation_status(purchase_orders[po])
        purchase_orders[po].po_date_sortable = Moment(purchase_orders[po].po_date).startOf('day')
        purchase_orders[po].upload_date_sortable = Moment(purchase_orders[po].upload_date).startOf('day')
        purchase_orders[po].po_date_formatted = this.formatDate(purchase_orders[po].po_date_sortable)
        purchase_orders[po].upload_date_formatted = this.formatDate(purchase_orders[po].upload_date_sortable)
        purchase_orders[po].vendor_page = this.db.vendors['vendor_'+purchase_orders[po].vendor_number] ? this.db.vendors['vendor_'+purchase_orders[po].vendor_number].page : ''
        purchase_orders[po].business_days_since_po_date = parseFloat(Moment(po_start).businessDiff(current))
        purchase_orders[po].needs_attention = (purchase_orders[po].business_days_since_po_date > 1 || purchase_orders[po].business_days_since_po_date === 1 && this.current_hour > 14) ? true : false
        purchase_orders[po].vendor_po_confirmation_email = this.db.vendors['vendor_'+purchase_orders[po].vendor_number].po_confirmation_email
        purchase_orders[po].vendor_escalation_email = this.db.vendors['vendor_'+purchase_orders[po].vendor_number].escalation_email
        purchase_orders[po].vendor_eta_inquiry_email = this.db.vendors['vendor_'+purchase_orders[po].vendor_number].eta_inquiry_email
        purchase_orders[po].vendor_auto_confirms = this.db.vendors['vendor_'+purchase_orders[po].vendor_number].self_confirm_po
        purchase_orders[po].vendor_self_track_eta = this.db.vendors['vendor_'+purchase_orders[po].vendor_number].self_track_eta
        purchase_orders[po].po_confirmation_last_requested_sortable = purchase_orders[po].po_confirmation_last_requested
        purchase_orders[po].po_confirmation_last_requested_formatted = this.formatDate(purchase_orders[po].po_confirmation_last_requested_sortable)
        purchase_orders[po].po_confirmation_requested_cnt = purchase_orders[po].action_po_conf_request_cnt

        // no_vendor_email_pending_purchase_orders_table_data
        if(purchase_orders[po].po_confirmation_status === 'Pending' && this.db.vendors['vendor_'+purchase_orders[po].vendor_number].po_confirmation_email === 'email missing' && !this.db.vendors['vendor_'+purchase_orders[po].vendor_number].self_confirm_po){
          modules_data.no_vendor_email_pending_purchase_orders_table_data.push(purchase_orders[po])
        }

        // auto_confirm_pending_purchase_orders_table_data
        if((purchase_orders[po].po_confirmation_status === 'Pending' && this.db.vendors['vendor_'+purchase_orders[po].vendor_number].self_confirm_po) || (purchase_orders[po].po_confirmation_status === 'Not Requested' && purchase_orders[po].has_archive)){

          if(purchase_orders[po].has_archive){
            purchase_orders[po].revised = 'YES'
          }else{
            purchase_orders[po].revised = ''
          }

          modules_data.auto_confirm_pending_purchase_orders_table_data.push(purchase_orders[po])
        }

        // not_requested_purchase_order_confirmations_table_data
        if(purchase_orders[po].po_confirmation_status === 'Not Requested' && !purchase_orders[po].has_archive){
          modules_data.not_requested_purchase_order_confirmations_table_data.push(purchase_orders[po])
          purchase_order_numbers_not_requested.push(po)
          this.not_requested_purchase_orders_send_confirmation_request[po] = this.not_requested_purchase_orders_send_confirmation_request[po] ? this.not_requested_purchase_orders_send_confirmation_request[po] : false
          this.not_requested_cnt++
        }
        // disputed_purchase_orders_table_data
        if(purchase_orders[po].po_confirmation_status === 'Disputed'){
          modules_data.disputed_purchase_orders_table_data.push(purchase_orders[po])
        }

        // escalation_po_confirmation_requests_table_data
        if(purchase_orders[po].po_confirmation_status === 'Pending' && purchase_orders[po].action_po_conf_request_cnt > 1){
          modules_data.escalation_po_confirmation_requests_table_data.push(purchase_orders[po])
        }

        // delayed_eta_utp_tbd_items_table_data
        // items_flagged_critical_table_data
        // items_multiple_pos
        // past_due_eta_items_table_data

        for(var i in purchase_orders[po].items){
          var item = purchase_orders[po].items[i]
          item.po_number = po
          item.vendor_name = purchase_orders[po].vendor_name
          item.vendor_number = purchase_orders[po].vendor_number
          item.vendor_page = this.db.vendors['vendor_'+item.vendor_number] ? this.db.vendors['vendor_'+item.vendor_number].page : ''
          item.po_date = purchase_orders[po].po_date
          item.po_date_sortable = purchase_orders[po].po_date_sortable
          item.po_date_formatted = purchase_orders[po].po_date_formatted
          item.buyer_name = purchase_orders[po].buyer_name
          item.eta_status = this.item_eta_status(purchase_orders[po],item.item_key)
          item.eta_last_requested_sortable = item.eta_last_requested ? Moment(item.eta_last_requested).startOf('day') : ''
          item.eta_last_requested_formatted = item.eta_last_requested ? this.formatDate(item.eta_last_requested_sortable) : ''
          item.eta_last_provided_sortable = item.eta_last_provided ? Moment(item.eta_last_provided).startOf('day') : ''
          item.eta_last_provided_formatted = item.eta_last_provided ? this.formatDate(item.eta_last_provided_sortable) : ''
          item.pending_eta_request = item.pending_eta_request ? 'Y' : ''
          item.quantity_backordered = item.qty_open + ' of ' + item.po_quantity
          item.flag_critical = item.flag_critical ? true : false
          item.po_confirmation_status = purchase_orders[po].po_confirmation_status
          item.eta_inquiry_email = purchase_orders[po].vendor_eta_inquiry_email
          item.vendor_escalation_email = purchase_orders[po].vendor_escalation_email
          item.vendor_self_track_eta = purchase_orders[po].vendor_self_track_eta
          item.dropship = item.po_status.indexOf('D') !== -1 ? 'Y' : 'N'

          var date_a = Moment(item.po_date)
          var date_b = Moment(item.eta)
          var date_c = Moment().startOf('day')
          item.full_lead_time_days = (item.eta !== '' && item.eta !== 'UTP' && item.eta !== 'TBD') ? date_b.diff(date_a, 'days') : 9999
          item.remaining_lead_time_days = (item.eta !== '' && item.eta !== 'UTP' && item.eta !== 'TBD') ? date_b.diff(date_c, 'days') : 9999


          if(purchase_orders[po].po_confirmation_status !== 'Complete' && item.eta_status !== 'Complete' && (item.eta === 'UTP' || item.eta === 'TBD' || (item.remaining_lead_time_days >= 30 && item.remaining_lead_time_days !== 9999))){
            modules_data.delayed_eta_utp_tbd_items_table_data.push(item)
          }

          if(purchase_orders[po].po_confirmation_status !== 'Complete' && item.eta_status === 'Past Due'){
            modules_data.past_due_eta_items_table_data.push(item)
          }

          if(purchase_orders[po].po_confirmation_status !== 'Complete' && item.eta_status !== 'Complete' && item.remaining_lead_time_days <= -1 && (item.eta_inquiry_email === 'email missing' || item.vendor_self_track_eta)){
            modules_data.self_track_eta_items_table_data.push(item)
          }

          if(purchase_orders[po].po_confirmation_status !== 'Complete' && item.eta_status !== 'Complete' && item.flag_critical){
            modules_data.items_flagged_critical_table_data.push(item)
          }

          if(purchase_orders[po].po_confirmation_status !== 'Complete' && item.eta_status !== 'Complete' && item.eta_escalation_requests_cnt > 1 && item.pending_eta_request === 'Y'){
            modules_data.escalated_eta_requests_table_data.push(item)
          }

          if(purchase_orders[po].po_confirmation_status !== 'Complete' && item.eta_status !== 'Complete'){
            if(!items_multiple_pos_cnt[item.product]){
              items_multiple_pos_cnt[item.product] = [{po_number: po, eta: item.eta, vendor_name: item.vendor_name, vendor_number: item.vendor_number}]
            }else{
              items_multiple_pos_cnt[item.product].push({po_number: po, eta: item.eta, vendor_name: item.vendor_name, vendor_page: item.vendor_page})
            }
          }
        }
      }

      for(var po in this.not_requested_purchase_orders_send_confirmation_request){
        if(purchase_order_numbers_not_requested.indexOf(po) === -1){
          delete this.not_requested_purchase_orders_send_confirmation_request[po]
        }
      }

      for(var item in items_multiple_pos_cnt){
        if(items_multiple_pos_cnt[item].length > 1){
          var obj = {}
          obj.detail = items_multiple_pos_cnt[item]
          obj.product = item
          obj.cnt = items_multiple_pos_cnt[item].length
          modules_data.items_on_multiple_purchase_orders_table_data.push(obj)
        }
      }

      return modules_data
    }
  },
  methods: {
    parseETA(eta){
      const dateRegex = /(\d{1,2})\/(\d{1,2})\/(\d{2}|\d{4})/;
      const match = eta.match(dateRegex);
      if (match) {
        return match[0];
      }
      return '1/1/00';
    },
    backorderNote(type,id){
      this.backorder_note_type = type
      this.backorder_key_for_component = id
      this.backorder_note_popup = true
    },    
    requestPoConfirmationAgain(vendor_number,po_number){

      var purchase_order_snapshot = this.db.purchase_orders[po_number]
      var vendor_info = this.db.vendors['vendor_'+purchase_order_snapshot.vendor_number]
      var vendor_name = vendor_info.name
      var confirm_send_email = confirm('Are you sure you want to send a PO confirmation request to ' + vendor_name + ' for purchase order ' + po_number + '?' )

      if(confirm_send_email){
        this.$store.state.isLoading = true
        var email = {
          po: po_number,
          to: vendor_info.po_confirmation_email+';'+vendor_info.escalation_email, // include Brian
          vendor: vendor_info.name,
          vendor_page: vendor_info.page,
          requested_by: this.user
        }
        this.poConfirmationRequestEscalationEmail(email)
        setTimeout(() => {
            this.$store.state.isLoading = false
        }, 3000);
      }

    },
    exportVendorNotRespondingToEtaRequests(){
      var items = this.purchase_orders_modules_data.escalated_eta_requests_table_data
      var export_arr = []

      for(var i = 0; i < items.length; i++){
        var item = items[i]
        var row_item = {
          'PO Number' : item.po_number,
          'PO Date': item.po_date_formatted,
          'Buyer': item.buyer_name,
          'ETA Inquiry Email': item.eta_inquiry_email,
          'Escalation Email': item.vendor_escalation_email,
          'Product Number' : item.product,
          'Vendor': item.vendor_name,
          'ETA Requests Count': item.eta_escalation_requests_cnt,
          'ETA Last Requested': item.eta_last_requested_formatted,
        }
        export_arr.push(row_item)
      }

      var wscols = [
          {wch:30},
          {wch:30},
          {wch:30},
          {wch:30},
          {wch:30},
          {wch:30},
          {wch:30},
          {wch:30}
      ];

      var worksheet = XLSX.utils.json_to_sheet(export_arr)
      worksheet['!cols'] = wscols;
      var workbook = XLSX.utils.book_new()
      var export_date = Date.now()
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'Vendor Not Responding to ETA Requests ' + export_date + '.xlsx')
    },
    exportProductMultiplePOs(){
      var items = this.purchase_orders_modules_data.items_on_multiple_purchase_orders_table_data
      var export_arr = []

      for(var i = 0; i < items.length; i++){
        var item = items[i]
        var row_item = {
          'RH Product #' : item.product,
          '# of POs': item.cnt,
          'POs': item.detail.map(item => item.po_number).join(', '),
          'Vendor': item.detail[0].vendor_name
        }
        
        export_arr.push(row_item)
      }

      var wscols = [
          {wch:30},
          {wch:20},
          {wch:50},
          {wch:50},
      ];

      var worksheet = XLSX.utils.json_to_sheet(export_arr)
      worksheet['!cols'] = wscols;
      var workbook = XLSX.utils.book_new()
      var export_date = Date.now()
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'Product on Mutiple POs ' + export_date + '.xlsx')

    },
    exportSelfTrackEtas(){
      var items = this.purchase_orders_modules_data.self_track_eta_items_table_data
      var export_arr = []

      for(var i = 0; i < items.length; i++){
        var item = items[i]
        var row_item = {
          'PO Number' : item.po_number,
          'PO Date': item.po_date_formatted,
          'Buyer': item.buyer_name,
          'ETA Inquiry Email': item.eta_inquiry_email,
          'Product Number' : item.product,
          'Vendor': item.vendor_name,
          'Dropship?': item.po_status.indexOf('D') !== -1 ? 'Y' : 'N',
          'ETA': item.eta,
        }

        if(item.remaining_lead_time_days === 9999){
          row_item['Remaining Lead Time'] = ''
        }

        if(item.remaining_lead_time_days > 1){
          row_item['Remaining Lead Time'] = item.remaining_lead_time_days + ' Days'
        }

        if(item.remaining_lead_time_days === 0){
          row_item['Remaining Lead Time'] = 'Due Today'
        }

        if(item.remaining_lead_time_days === -1){
          row_item['Remaining Lead Time'] = Math.abs(item.remaining_lead_time_days) + ' Day Past Due'
        }

        if(item.remaining_lead_time_days < -1 ){
          row_item['Remaining Lead Time'] = Math.abs(item.remaining_lead_time_days) + ' Days Past Due'
        }

        export_arr.push(row_item)
      }

      var wscols = [
          {wch:30},
          {wch:30},
          {wch:30},
          {wch:30},
          {wch:30},
          {wch:30},
          {wch:30},
          {wch:30},
          {wch:30}
      ];

      var worksheet = XLSX.utils.json_to_sheet(export_arr)
      worksheet['!cols'] = wscols;
      var workbook = XLSX.utils.book_new()
      var export_date = Date.now()
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'Self Track These ETAs ' + export_date + '.xlsx')
    },
    exportFlaggedCritical(){
      var items = this.purchase_orders_modules_data.items_flagged_critical_table_data
      var export_arr = []

      for(var i = 0; i < items.length; i++){
        var item = items[i]
        var row_item = {
          'PO Number' : item.po_number,
          'Product Number' : item.product,
          'PO Date': item.po_date_formatted,
          'Buyer': item.buyer_name,
          'Vendor': item.vendor_name,
          'ETA Last Updated': item.eta_last_provided_formatted,
          'Item ETA Status': item.eta_status,
          'ETA': item.eta,
        }
        export_arr.push(row_item)
      }

      var wscols = [
          {wch:30},
          {wch:30},
          {wch:30},
          {wch:30},
          {wch:30},
          {wch:30},
          {wch:30},
          {wch:30}
      ];

      var worksheet = XLSX.utils.json_to_sheet(export_arr)
      worksheet['!cols'] = wscols;
      var workbook = XLSX.utils.book_new()
      var export_date = Date.now()
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'Items Flagged Critical ' + export_date + '.xlsx')
    },
    exportEscaltedPoRequests(){
        var items = this.purchase_orders_modules_data.escalation_po_confirmation_requests_table_data
        var export_arr = []

        for(var i = 0; i < items.length; i++){
          var item = items[i]
          var row_item = {
            'PO Number' : item.po_number,
            'PO Date': item.po_date_formatted,
            'Buyer': item.buyer_name,
            'PO Confirmation Email': item.vendor_po_confirmation_email,
            'Escalation Email': item.vendor_escalation_email,
            'Vendor': item.vendor_name,
            'Last Requested': item.po_confirmation_last_requested_formatted,
            'Requested Count': item.po_confirmation_requested_cnt
          }
          export_arr.push(row_item)
        }

        var wscols = [
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30}
        ];

        var worksheet = XLSX.utils.json_to_sheet(export_arr)
        worksheet['!cols'] = wscols;
        var workbook = XLSX.utils.book_new()
        var export_date = Date.now()
        XLSX.utils.book_append_sheet(workbook, worksheet)
        XLSX.writeFile(workbook, 'Escalated PO Confirmation Requests ' + export_date + '.xlsx')

    },
    exportDelayedItems(){
        var items = this.purchase_orders_modules_data.delayed_eta_utp_tbd_items_table_data
        var export_arr = []

        for(var i = 0; i < items.length; i++){
          var item = items[i]
          var row_item = {
            'PO Number' : item.po_number,
            'PO Date': item.po_date_formatted,
            'Buyer': item.buyer_name,
            'RH Product #': item.product,
            'Product Description': item.product_description,
            'Vendor': item.vendor_name,
            'Item ETA': item.eta,
            'Remaining Lead Time': item.remaining_lead_time_days === 9999 ? item.eta : item.remaining_lead_time_days + ' Days',
            'Internal Notes': '',
            'Shared Notes': ''
          }

          for(var n in item.internal_notes){
            row_item['Internal Notes'] += item.internal_notes[n].note + ' (' + item.internal_notes[n].submitted_by + ' on ' + this.formatDate(n.submitted_date) + '), '
          }

          for(var n in item.shared_notes){
            row_item['Shared Notes'] += item.shared_notes[n].note + ' (' + item.shared_notes[n].submitted_by + ' on ' + this.formatDate(n.submitted_date) + '), '
          }


          export_arr.push(row_item)
        }

        var wscols = [
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:100},
            {wch:100},
        ];

        var worksheet = XLSX.utils.json_to_sheet(export_arr)
        worksheet['!cols'] = wscols;
        var workbook = XLSX.utils.book_new()
        var export_date = Date.now()
        XLSX.utils.book_append_sheet(workbook, worksheet)
        XLSX.writeFile(workbook, 'Delayed Items ' + export_date + '.xlsx')

    },
    exportPastDueItems(){
        var items = this.purchase_orders_modules_data.past_due_eta_items_table_data
        var export_arr = []

        for(var i = 0; i < items.length; i++){
          var item = items[i]
          var row_item = {
            'PO Number' : item.po_number,
            'PO Date': item.po_date_formatted,
            'Buyer': item.buyer_name,
            'RH Product #': item.product,
            'Product Description': item.product_description,
            'Vendor': item.vendor_name,
            'Item ETA': item.eta,
            'Days Past Due': item.remaining_lead_time_days === 9999 ? item.eta : Math.abs(item.remaining_lead_time_days) + ' Days Past Due',
            'Internal Notes': '',
            'Shared Notes': ''
          }

          for(var n in item.internal_notes){
            row_item['Internal Notes'] += item.internal_notes[n].note + ' (' + item.internal_notes[n].submitted_by + ' on ' + this.formatDate(n.submitted_date) + '), '
          }

          for(var n in item.shared_notes){
            row_item['Shared Notes'] += item.shared_notes[n].note + ' (' + item.shared_notes[n].submitted_by + ' on ' + this.formatDate(n.submitted_date) + '), '
          }


          export_arr.push(row_item)
        }

        var wscols = [
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:100},
            {wch:100},
        ];

        var worksheet = XLSX.utils.json_to_sheet(export_arr)
        worksheet['!cols'] = wscols;
        var workbook = XLSX.utils.book_new()
        var export_date = Date.now()
        XLSX.utils.book_append_sheet(workbook, worksheet)
        XLSX.writeFile(workbook, 'Past Due Items ' + export_date + '.xlsx')

    },    
    sizeTable(){
      var wh = window.innerHeight;
      this.table_height = wh - 260
    },
    sendConfirmationRequests(tab){
      var confirm_send_emails = confirm('Are you sure you want to send' + (this.po_confirmation_request_cnt === 1 ? ' a' : '')  + ' confirmation request' + (this.po_confirmation_request_cnt > 1 ? 's' : '') + ' for the ' + this.po_confirmation_request_cnt + ' selected purchase order' + (this.po_confirmation_request_cnt > 1 ? 's' : '') + '?' )

      this.$store.state.isLoading = true

      if(!confirm_send_emails){
        this.$store.state.isLoading = false
      }

      if(tab === 'Not Requested' && confirm_send_emails){
        this.$store.state.isLoading = true
        for(var po in this.not_requested_purchase_orders_send_confirmation_request){
          if(this.not_requested_purchase_orders_send_confirmation_request[po]){
            var po_number = po
            var purchase_order_snapshot = this.db.purchase_orders[po]
            var vendor_info = this.db.vendors['vendor_'+purchase_order_snapshot.vendor_number]
            var vendor_name = vendor_info.name
            var vendor_po_confirmation_email = vendor_info.po_confirmation_email
            var send_to_string = vendor_po_confirmation_email
            var vendor_page = vendor_info.page
            var email = {
              po: po_number,
              to: send_to_string,
              vendor: vendor_name,
              vendor_page: vendor_page,
              requested_by: this.user
            }
            this.poConfirmationRequestEmail(email)
          }
        }
      }

      setTimeout(() => {
          this.$store.state.isLoading = false
      }, 2000);

    },
    selectAll(tab){
      if(tab === 'Not Requested'){
        for(var po in this.not_requested_purchase_orders_send_confirmation_request){
          this.not_requested_purchase_orders_send_confirmation_request[po] = true
        }
      }
    },
    unselectAll(tab){
      if(tab === 'Not Requested'){
        for(var po in this.not_requested_purchase_orders_send_confirmation_request){
          this.not_requested_purchase_orders_send_confirmation_request[po] = false
        }
      }
    },
    addNote(po,type){
      this.po_for_component = po
      this.note_type = type
      this.note_popup = true
    },
    flagCritical(po_number,item_key){
      firebase.database().ref('purchase_orders').child(po_number).child('items').child(item_key).child('flag_critical').set(true)
    },
    unflagCritical(po_number,item_key){
      var purchase_order = this.db.purchase_orders[po_number]
      var item = purchase_order.items[item_key].product
      var confirm_unflag = confirm('Are you sure you want to unflag ' + item + '?')
      if(confirm_unflag){
        firebase.database().ref('purchase_orders').child(po_number).child('items').child(item_key).child('flag_critical').set(false)
      }
    },
    openPurchaseOrderConfirmationPopUp(po){
      this.po_for_component = po
      this.vendor_info_for_component = this.db.vendors['vendor_'+this.db.purchase_orders[po].vendor_number]
      this.show_po_confirmation_popup = true
    },
    closePurchaseOrderConfirmationPopUp(){
      this.po_for_component = null
      this.vendor_info_for_component = null
      this.show_po_confirmation_popup = false
    },
    openPurchaseOrderDetailPopup(po){
      this.po_for_component = po
      this.po_detail = true
    },
    closePurchaseOrderDetailPopup(){
      this.po_for_component = null
      this.po_detail = false
    },
    openItemDetailPopup(po,item){
      this.po_for_component = po
      this.item_for_component = item
      this.item_detail = true
    },
    closeItemDetailPopup(){
      this.po_for_component = null
      this.item_for_component = null
      this.item_detail = false
    },
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    },
    formatDateTime(date){
      return Moment(date).format('MM/DD/YY hh:mma')
    }
  }
}
</script>

<style scoped>

  .v-card .v-card-title{
    color: gray;
    font-size: 14px;
  }

  .v-card .v-card-text {
    line-height: 100%;
  }

  .table-container{
    position: relative;
    top: 5px;
    margin:0px 20px;
  }

  .flagged{
    width: 20px;
    height: 20px;
    border:1px solid #ff7214;
    background: #ff7214;
    cursor: pointer;
  }

  .unflagged{
    width: 20px;
    height: 20px;
    border:1px solid #ff7214;
    cursor: pointer;
  }

  .needs_attention{
    background: red;
    color: white;
    width: 15px;
    height: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 140%;
    font-weight: bold;
    border-radius: 100%;
  }

  a{
    text-decoration: none;
  }

  .v-card-actions{
    min-height: 0px;
    padding: 4px 0px;
  }

  .dashboard-link{
    color:#ff7214;
    cursor:pointer;
    font-weight: bold;
    font-size: 11px;
    /*text-decoration: underline;*/

  }
</style>
